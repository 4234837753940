
import { toStr, toObj, toAry } from "../libs/libType";
import { _ATAllRoles, 
    _MaxLenATLogin, _MaxLenATPass, _MaxLenATSubName, _MaxLenMetaSetId, _MaxLenSubId, _MaxLenTodo, 
    _MinLenATLogin, _MinLenATPass, _MinLenATSubName, _MinLenMetaSetId, _MinLenSubId, _MinLenTodo } from "./ATConsts";
import { setIf, setIfErr, toUniIdAry, toIdStr, validIdCharsMinMax, validMinMax } from "./ATValidate";

// ATUser validators ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const validATLogin = (str) => validIdCharsMinMax(toStr(str), _MinLenATLogin, _MaxLenATLogin, 'Login ID');
export const validATPass = (str) => validMinMax(toStr(str), _MinLenATPass, _MaxLenATPass, 'Password');

export const normalizeEditATUser = (fields) => {
    const fs = toObj(fields);

    const ATUserId = toIdStr(fs.ATUserId);  
    return {
        //...fs,
        ATUserId, 
        ATLogin: toIdStr(fs.ATLogin),
        ATRole: _ATAllRoles.includes(fs.ATRole)?fs.ATRole:'',
    
        pass: toStr(fs.pass),
        pass2: toStr(fs.pass2),
    
        USubjIds: toUniIdAry(fs.USubjIds), 

        editPass: (fs.editPass || (!ATUserId))?1:0,
        isNew: fs.isNew?1:0,
    };
};


export const validEditATUser = (fields) => {
    const fieldErrs = {};
    setIfErr(fields, fieldErrs, 'ATLogin', validATLogin);
    if(fields.editPass){
        setIfErr(fields, fieldErrs, 'pass', validATPass);
        setIf(fieldErrs, 'pass2', (fields.pass2!==fields.pass) && 'Password Confirm unmatch');
    }
    setIf(fieldErrs, 'ATRole', (_ATAllRoles.includes(fields.ATRole))?'':'Please Select Role');
    setIf(fieldErrs, 'USubjIds', (toAry(fields.USubjIds).length)?'':'Please select at least one subject');
    return fieldErrs;
  };
  