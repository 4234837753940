import React, {useContext, useMemo, useEffect, useState } from 'react';
import { Accordion, AccordionContext, Card, Container, Modal, useAccordionButton } from 'react-bootstrap';

import { _WebRoot, _ATRoot } from '../../consts/ATConstReact';

import ScrollContainer from '../_components/CpScrollContainer';
import { debugMode, ReduxBind } from '../../saga/ReduxState';
import PageEXPFLayout from '../PageEXPFLayout';
import { useUILang } from '../../AppExPf/utils/useUILang';
import CpHeaderTitle from '../_components/CpHeaderTitle';
import CpDropdown, { ent2DropdownItem, obj2DropdownItem } from '../_components/CpDropdown';

import { useYearItems } from '../EPAssign/TabAssignmentSettingClass';
import { useCaches } from '../../AppExPf/utils/useCaches';
import { BtnDev, BtnPopDev, LoadIco, preJS } from '../../AppExPf/AppUtil';
import { isObj, objEntries, objKeys, objVals, strCmp, toAry, toObj, toStr } from '../../libs/libType';
import { toIdStr, toUniIdAry } from '../../consts/ATValidate';
import { _ATMetaTransByCode, _ATMetaTypeByCode, _ATMetaTypeCodes } from '../../consts/ATMetaTypes';
import CpIco from '../_components/CpIco';
import { IconList } from '../../consts/ATIconListToUsePoc';
import CpTeacherReport from './CpTeacherReport';

import { isMobileScreen } from '../../poc/helper/getWidnowWidth';
import { RT_detail, RT_meta, RT_over, useRptCtnOpts, useRptOpts } from './PageReports';
import { CpLastUpdate} from './PageReportStudent';
import { rpt_keyStg } from '../../consts/ATSysQType';
import WholeKeyStageChartElement from '../../poc/screens/pages/sample-page/WholeKeyStageChartElement';

import WholeKeyStageMetadataProgressBySelectedClassTeacherData from "../../poc/screens/shared/includes/chart/sample-data/wholeKeyStageMetadataProgressBySelectedClassTeacherData.json";
import WholeKeyStageMetadataProgressByStudentSharedData from "../../poc/screens/shared/includes/chart/sample-data/wholeKeyStageMetadataProgressByStudentSharedData.json";
import WholeKeyStageMetadataProgressChart from '../../poc/screens/shared/includes/chart/WholeKeyStageMetadataProgressChart';

export const rpTime_year = 'rpTimeY';
export const rpTime_keyStg = 'rpTimeKS';
export const rptPg_noa = 'rpNOA'; //report-no-of-assignment
export const rptPg_asp = 'rpASP'; //report-average-score-percentage

const PageReportTeacher = ReduxBind(props => { //base on POC ReportTeacher
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();

    const rptOpts = useRptOpts();
    const {fields, setFields, setField, opts, setOpts} = rptOpts;

    const [ cache2d, useGetCache ] = useCaches(props);
    const yc2d = useGetCache('yearClass2d', '*');
    const yearClass2d = toObj(yc2d);

    const {year, grade, cls, stu, } = toObj(fields);
    const yearItems = useYearItems(yc2d);
    const y = yearClass2d[year]? year: objKeys(yearItems)[0];   

    const classs = useMemo(() => toObj(yearClass2d[y]), [yc2d, y]);
    const grades = useMemo(() => gradesByCls(classs), [classs]);
    const gradeItems = useMemo(() => ent2DropdownItem(grades.map(g => [toIdStr(g), toIdStr(g)])), [grades]);
    const g = toStr(gradeItems[grade]? grade: objKeys(gradeItems)[0]);   
    const gName = g;

    const gradeClsEnts = useMemo( ()=> objEntries(classs).filter(([clsId, c]) => (c.grade === gName)), [classs, gName]); 
    
    const classItems = useMemo(() => ent2DropdownItem([ ['*', t('all')],
        ...gradeClsEnts.sort(([i, c], [j, d]) => strCmp(c.nameEng, d.nameEng) ).map(([i, c]) => [i, ut(c.nameEng, c.nameChi)])
    ]), [gradeClsEnts, uiEn]);
    const c = classItems[cls]? cls: objKeys(classItems)[0];   
    const oneCls = c && (c !== '*');

    const clsStudents = useGetCache('classStudents', oneCls? c: '');
    const studentItems = useMemo(() => ent2DropdownItem([ ['*', t('all')],
        ...objEntries(clsStudents).sort((i,j) => (i[1].classNo - j[1].classNo)).map(([sid, s]) => [sid, ut(s.nameEng, s.nameChi)])
    ]), [clsStudents, uiEn]);
    const st = studentItems[stu]? stu: objKeys(studentItems)[0];
    const oneStu = oneCls && st && (st !== '*');

    const _subjMetas = useGetCache('subjMetas', '*');
    const rptClsOpts = {yearItems, y, gradeItems, g, classItems, c, oneCls, studentItems, st};
    const rptCtnOpts = useRptCtnOpts(fields, opts, _subjMetas, 1, g, oneCls, oneStu, uiEn, t, ut);

    useEffect(() => { setField('gradeClsIds')(gradeClsEnts.map(e => e[0])); }, [gradeClsEnts]);

    return <PageEXPFLayout sideIdx='report'
        menuTitle={<CpHeaderTitle title={t("sidemenu-reports")} iconPath={IconList.menu.report} animation={true} />} 
        menuActionBar=''//{<CreateExerciseCTA/>} 
        header=''//'headerheaderheader'
        subBody=''//'subBodysubBody'
        footer=''>
        <ScrollContainer className={"scroll-container h-100 d-flex flex-column w-100 overflow-auto"}>
            <CpTeacherReportFilter {...{rptOpts, rptClsOpts, rptCtnOpts}} />
{debugMode()?<BtnDev txt='POC_KEY_STAGE_Demo'>
<div className={"report report-table-progress-chart"}>
    <WholeKeyStageChartElement type="ks-report-selected-student" />
    <WholeKeyStageChartElement type="ks-report-selected-class" />
    <WholeKeyStageMetadataProgressChart {...(WholeKeyStageMetadataProgressBySelectedClassTeacherData)} />
    <WholeKeyStageMetadataProgressChart {...(WholeKeyStageMetadataProgressByStudentSharedData)} />
</div>
</BtnDev>:''}
            <CpTeacherReport {...{rptOpts, rptClsOpts, rptCtnOpts, clsStudents}} />
        </ScrollContainer>
    </PageEXPFLayout>;
});
export default PageReportTeacher;

const CpTeacherReportFilter = ReduxBind(props => {
    const { dispatch, rptCtnOpts, rptOpts, rptClsOpts} = props;
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();

    const {yearItems, y, gradeItems, g, classItems, c, oneCls, studentItems, st} = rptClsOpts;
    const {fields, setFields, setField, opts, setOpts, setRJ, setRMT} = toObj(rptOpts);

    const {
        subjDDI, MTypeDDI, MSetDDI, ML1DDI, rptDDI, rpTimeDDI,
        r, j, mt, ms, l1, rti,
        rpt, MSubj, MType, MSet, ML1, rpTime, canKS, hasKS,
    } = toObj(rptCtnOpts);
    const rptMeta = (r === RT_meta);
 
//useEffect(()=>{console.log({hasKS, rpt_keyStg, rptCtnOpts, rptClsOpts})}, [hasKS, rpt_keyStg, rptCtnOpts, 
//    yearItems, y, gradeItems, g, classItems, c, oneCls, studentItems, st]);

    return <Container fluid={true} className={"CpTeacherReportFilter mt-3"}>
{preJS({y, g, c, j, r, mt, ms, l1, rti},)}
        <div className={"CpTeacherReportFilter rounded-light border bg-dim-100 mb-3"}>
            <Accordion defaultActiveKey={isMobileScreen()? "1": "0"} className={"w-100"}>
                <div className={"d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100"}>
                    <CpAccordionOpenCTA eventKey="0">{toStr(rptDDI?.[rpt || RT_over]?.t)}</CpAccordionOpenCTA>
                    <span className={"d-flex gap-3"}><CpLastUpdate /></span>
                </div>
                <Accordion.Collapse eventKey="0" className={"bg-transparent"}><div>
                    <hr className={"mt-0 border-dim-400"}/>
                    <div className={"d-flex flex-column flex-xl-row flex-wrap gap-2 justify-content-between px-2 pb-2"}>
                        {/*<OptionsContainer title={t("report-type")} options={teacherReportsOptions()} */}
                        {/*     className={"option-report"} cbItem={handleReport} activeItem={report.type} />*/}
                        <div className={"d-flex gap-3 flex-wrap justify-content-start w-100"}>
                            <CpOptionsContainer id='DD_subj' options={subjDDI} cbItem={setRJ} activeItem={j} 
                                title={t("subject")} className={"option-subject"} />
                            <CpOptionsContainer id='DD_Year' options={yearItems} cbItem={setField('year')} activeItem={y} 
                                title={t("year")} className={"option-year"} />
                            <CpOptionsContainer id='DD_Grade' options={gradeItems} cbItem={setField('grade')} activeItem={g}
                                title={t("grade")} className={"option-grade"} />
                            <CpOptionsContainer id='DD_class' options={classItems} cbItem={setField('cls')} activeItem={c}
                                title={t("class")} className={"option-class"} />

                            {oneCls && <CpOptionsContainer id='DD_student' options={studentItems} cbItem={setField('stu')} activeItem={st}
                                title={t("student")} className={"option-student"} />}
                            <CpOptionsContainer id='DD_rpt' options={rptDDI} cbItem={setField('rpt')} activeItem={r}
                                title={t("report-type")} className={"option-report"} />

                            {rptMeta && <CpOptionsContainer id='DD_MType' options={MTypeDDI} cbItem={setRMT} activeItem={mt}
                                title={t("report-metadata")} className={"option-metadata"} />}
                            {rptMeta && <CpOptionsContainer id='DD_MSet' options={MSetDDI} cbItem={setField('MSet')} activeItem={ms}
                                title={t("report-metadata-set")} className={"option-metadata"} />}
                            {hasKS? <CpOptionsContainer id='DD_RpTime' options={rpTimeDDI} cbItem={setField('rpTime')} activeItem={rti}
                                title={t("period")} className={"option-metadata"} />: ''}
                        </div>
                    </div>
                </div></Accordion.Collapse>
            </Accordion>
        </div>
    </Container>;
});

export const CpOptionsContainer = (props) => {
    const [t, isUIEn, lang, setLang, ut, t2, t3] = useUILang();
    const {title = '', cbItem, activeItem = "", disabled = false, ...optionsProps} = props
    const o = props.options;
    const options = isObj(o)? o: {};
    const id = toStr(props.id); 

    return <span {...{id, className:`CpOptionsContainer d-flex flex-wrap flex-md-nowrap gap-2 align-items-center ${optionsProps.className}`}} >
        <span className={"semi-bold text-nowrap"}>{title}:</span>
        <span className={"d-flex report-max-options-width"}>
            <CpDropdown {...{id, items: options, cb: cbItem, idx: activeItem, className:`w100 ${disabled ? "bg-dim-100 pe-none" : ""}`}} />
        </span>
    </span>
};

const gradesByCls = cls => toUniIdAry(objVals(cls).map(c => c.grade)).sort(strCmp);

/*
const CpTeacherDownloadExcelCTA = () => {
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();
    const [show, setShow] = useState(false);
    const handleSearch = async () => { setShow(prev => true); downloadFile(); setShow(prev => false); };
    const downloadFile = () => { const link = document.createElement('a'); link.href = "/static/file/demo/report-dummy-file.xlsx"; };
    return <>
        <CpActionButton className={`btn excel-btn text-nowrap gap-2 rounded-light semi-bold user-select-none`}
            onClick={handleSearch} title={t("download-excel")} iconPath={IconList.file.excel} hiddenText={true} />
        <Modal show={show} className={"justify-content-center"} backdrop="static" centered dialogClassName="loading-modal">
            <div className={"loading-animate bg-white rounded-light border shadow"}>{LoadIco('60px')}</div>
        </Modal>
    </>;
};
*/

export const rptTypeDDI = (t, d) => { //base on POC teacherReportsOptions
    return ent2DropdownItem([
        [RT_over, t("overview")],
        d && [RT_detail, t("report-class-performance-details")],
        [RT_meta, t("teacher-report-metadata-analysis")]
        //...toAry(MSTypes).map(mst => [mst, t(_ATMetaTransByCode(mst))]) //title: `${t("schema:dummy.curriculum")} - ${t("schema:dummy.subject.math")}`,
    ].filter(o => o));
};

export const CpAccordionOpenCTA = ({children, eventKey, callback}) => {
    const {activeEventKey} = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey),);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <span onClick={decoratedOnClick} role="button" className={"CpAccordionOpenCTA d-flex gap-2 align-items-center text-wrap"}>
            <span className={"text-wrap"}>{children}</span>
            <span className={"fs-5"}><CpIco src={isCurrentEventKey ? IconList.general.arrowUp : IconList.general.arrowDown}/></span>
        </span>
    );
}