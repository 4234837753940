
import { toStr, toObj } from "../libs/libType";
import { _ATAllRoles, 
    _MaxLenATLogin, _MaxLenATPass, _MaxLenATSubName, _MaxLenMetaSetId, _MaxLenSubId, _MaxLenTodo, 
    _MinLenATLogin, _MinLenATPass, _MinLenATSubName, _MinLenMetaSetId, _MinLenSubId, _MinLenTodo } from "./ATConsts";
import { setIfErr, toIdStr, validIdCharsMinMax, validMinMax } from "./ATValidate";

// ATUser validators ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const validATLogin = (str) => validIdCharsMinMax(toStr(str), _MinLenATLogin, _MaxLenATLogin, 'Login ID');
export const validATPass = (str) => validMinMax(toStr(str), _MinLenATPass, _MaxLenATPass, 'Password');

export const normalizeEditATSubj = (fields) => {
    const fs = toObj(fields);
    return {
        //...fs,
        ATSubjectId: toIdStr(fs.ATSubjectId),
        ATSubjectName: toStr(fs.ATSubjectName).trim(),
        isNew: fs.isNew?1:0,
    };
};

// ATSubject validators ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
export const validATSubjectId = (str) => validIdCharsMinMax(toStr(str), _MinLenSubId, _MaxLenSubId, 'Subject ID');
export const validATSubjectName = (str) => validMinMax(toStr(str), _MinLenATSubName, _MaxLenATSubName, 'Subject Name');

export const validEditATSubj = (fields) => {
    const fieldErrs = {};
    setIfErr(fields, fieldErrs, 'ATSubjectId', validATSubjectId);
    setIfErr(fields, fieldErrs, 'ATSubjectName', validATSubjectName);
    return fieldErrs;
};