import { useEffect, useState } from 'react';

import { ReduxBind } from '../saga/ReduxState';

import { fAKEdownload } from '../libs/libDownload';
import { routesMap, routeUse } from '../libs/libRouter';
import { isObj, isStr } from '../libs/libType';
import * as UI from '../libs/libUI';

import { _ATRoot, _WebRoot } from '../consts/ATConstReact';
import { IconList } from '../consts/ATIconListToUsePoc';
import { clickUrl, PopA } from './AppUtil';
import PageATQtnDev from './PageATQtnDev';

import CpLeftBar from './components/CpLeftBar';
import CpTopBar from './components/CpTopBar';
/**/
import PageATESetEdit from './ATESetEdit/PageATESetEdit';
import PageATESets from './ATESetEdit/PageATESets';
import PageATExerEdit from './ATExerEdit/PageATExerEdit';
import PageATExers from './ATExerEdit/PageATExers';
import PageATQImport from './ATQImport/PageATQImport';
import PageATQSetEdit from './ATQSetEdit/PageATQSetEdit';
import PageATQSets from './ATQSetEdit/PageATQSets';
import PageATQtnEdit from './ATQtnEdit/PageATQtnEdit';
import PageATQtns from './ATQtnEdit/PageATQtns';
import PageATAccountEdit from './PageATAccountEdit';
import PageATAccounts from './PageATAccounts';
import PageATLogin from './PageATLogin';
import PageATMetaEdit from './ATMetaEdit/PageATMetaEdit';
import PageATMetas from './ATMetaEdit/PageATMetas';
import PageATSubjectEdit from './PageATSubjectEdit';
import PageATSubjects from './PageATSubjects';
import PageDev from '../App/AppDev/PageDev';
import { useUILang } from './utils/useUILang';
import PageATQMetas from './ATQImport/PageATQMetas';
import PageATQMetaEdit from './ATQImport/PageATQMetaEdit';
import { expf2 } from '../consts/ATSysQType';
import PageATSubRemes from './PageATSubRemes';
import PageATSubRemeEdit from './PageATSubRemeEdit';
import { langCodeEn } from '../consts/ATConsts';

const PageAT = ReduxBind((props) => { 
    const [t, isUIEn, lang, setLang] = useUILang();
    const [visLB, setVisLB] = useState(1);
    const path = window.location && window.location.pathname;
    //const role = toStr(props?._saga?.auth?.ATUser?.ATRole);
       
    useEffect(()=>{ setLang(langCodeEn) },[]);

    //lj({PageAT:props._saga.auth});
    if(!(props._saga?.auth?.ATUserId))
        return <PageATLogin/>;

    const clickLB = () => setVisLB(!visLB);

    const route = routesMap(path, [
        [_ATRoot + 'exercisesets', PageATESets],
        [_ATRoot + 'exerciseset/edit/:ESetId', PageATESetEdit, {isNew:0}],
        [_ATRoot + 'exerciseset/new/:ESetId', PageATESetEdit, {isNew:1}],
        [_ATRoot + 'exerciseset/new', PageATESetEdit, {isNew:1}],

        [_ATRoot + 'exercises', PageATExers],
        [_ATRoot + 'exercise/edit/:EId', PageATExerEdit, {isNew:0}],
        [_ATRoot + 'exercise/new/:EId', PageATExerEdit, {isNew:1}],
        [_ATRoot + 'exercise/new', PageATExerEdit, {isNew:1}],

        [_ATRoot + 'accounts', PageATAccounts],
        [_ATRoot + 'account/edit/:ATUserId', PageATAccountEdit],
        [_ATRoot + 'account/new', PageATAccountEdit],

        [_ATRoot + 'subjects', PageATSubjects],
        [_ATRoot + 'subject/edit/:ATSubjectId', PageATSubjectEdit],
        [_ATRoot + 'subject/new', PageATSubjectEdit],

        [_ATRoot + 'subjectfollowups', PageATSubRemes, ],
        [_ATRoot + 'subjectfollowup/edit/:SMId', PageATSubRemeEdit, {isNew:0}],
        [_ATRoot + 'subjectfollowup/new', PageATSubRemeEdit, {isNew:1}],

        [_ATRoot + 'metas', PageATMetas],
        [_ATRoot + 'meta/edit/:metaSetId', PageATMetaEdit, {isNew:0}],
        [_ATRoot + 'meta/new/:metaSetId', PageATMetaEdit, {isNew:1}],
        [_ATRoot + 'meta/new', PageATMetaEdit, {isNew:1}],

        [_ATRoot + 'questionsets', PageATQSets],
        [_ATRoot + 'questionset/edit/:QSetId', PageATQSetEdit, {isNew:0}],
        [_ATRoot + 'questionset/new/:QSetId', PageATQSetEdit, {isNew:1}],
        [_ATRoot + 'questionset/new', PageATQSetEdit, {isNew:1}],
        
        expf2 && [_ATRoot + 'questions/metas', PageATQMetas, {}],
        expf2 && [_ATRoot + 'questions/meta/edit/:QId', PageATQMetaEdit, {}],

        
        [_ATRoot + 'questions/import', PageATQImport, {isNew:1, import:1}],
        [_ATRoot + 'questions', PageATQtns],
        [_ATRoot + 'question/edit/:QId', PageATQtnEdit, {isNew:0}],
        [_ATRoot + 'question/new/:QId', PageATQtnEdit, {isNew:1}],
        [_ATRoot + 'question/new', PageATQtnEdit, {isNew:1}],

        [_ATRoot + 'question/dev', PageATQtnDev],

        [_ATRoot + 'icons', PageATBody],

        [_ATRoot + 'dev', PageDev],
        [_ATRoot + 'dev/:dev', PageDev],

        [_ATRoot, PageATQtns],
    ].filter(i => i));
    const page = (route)?routeUse(route):'';//<PageATBody/>;

    return <>        
        <CpTopBar setVisLB={clickLB} />
        <CpLeftBar vis={visLB} gotoPath={path} path={path} clickX={()=>setVisLB(0)}/>
        <div className={'ATHead ATBody '+(visLB?'wLB':'woLB')}>{page}</div>
    </> 
});
export default PageAT;

const clickFDL = e => {
    UI.stopEvent(e);
    fAKEdownload('lalalalala', 'lala.csv', 'text/csv'); 
};

const PageATBody = ReduxBind(props => {
    const dispatch = props.dispatch;
    return <>
    <div className='flexColStart'>
        <h1>PageAT</h1>
        <AllIcons />
        {UI.Button0('DL CSV', clickFDL, 'btndl', '')}
        {UI.Button0('Btn CU', clickUrl(dispatch, _WebRoot+'cu'), 'btn1', '')}
        {UI.Button0('Btn Z', clickUrl(dispatch, _WebRoot+'z'), 'btn2', '')}
        <IndexPageBody />
        {false && <>
            <DummyTextDiv />
            <DummyTextDiv />
            <DummyTextDiv />
        </>}
    </div>        
    </>;
});

const walkIcons = (i, srcs, ps) => {
    if(isStr(i)){
        srcs.push(i);
    }else if(isObj(i)){
        Object.entries(i).forEach(([k,v]) => {walkIcons(v, srcs, ps+'.'+'k')});
        srcs.push('');
    }
};

export const AllIcons= props => {
    const srcs = [];
    walkIcons(IconList, srcs, '');
    return <div>
        <h2>All Icons</h2>
        <div style={{color:'#04b'}}>
        {srcs.map((s, i) => {
            if(!s) return <hr key={i} />;
            const src = process.env.REACT_APP_WEBROOT + 'static/icon/' + s;
            return <div key={i} className="w box1 br3 tac m4 p4 f12" style={{minWidth:'160px'}}>
            <div><img src={src} /></div>
            <div>{s}</div>
            </div>;
        })}
        </div>
    </div>;
}; 
const DummyTextDiv = (props => {
    return (<div style={{border:'1px solid #888', margin:'8px', padding:'8px'}} >
        <p>This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text. <br/></p>
        <p>This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text. <br/></p>
        <p>This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text. <br/></p>
        <p>This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text, This is Dummy Text. <br/></p>
    </div>);
});



const IndexPageBody = (props => {
    return (<>
        S3 web bucket for frontend DEV: expdev1-exercise-platform-front-end-infrastructure-dev <br/>
        Cloudfront ID: E352SXGONPFP5V <br/>
        <h1>Figma</h1>
<div>
Authoring Tool: 
    {PopA('https://www.figma.com/file/4pE6hDupsvdZ1zlVcuvczF/5809-Exercise-Platform---Authoring-Tool')}
</div><div>
Exercise and Question Category:
    {PopA('https://www.figma.com/file/NSV0NRjiB3e4Fa6R4VOI5d/Exercise-Platform-(Internal-AT)---CHM-(UI-Updating)?node-id=0%3A1')}
</div><div>
Frontend (Teacher):
    {PopA('https://www.figma.com/file/vjU0lP8Lhx7jy2J4lHFuLj/5809-Exercise-Platform---Client-end-(Teacher)-(UI-Updating)?node-id=1%3A438')}
</div><div>
Frontend (Student):
    {PopA('https://www.figma.com/file/vqydNNFvpm7yJ6XN7L6zoT/5809-Exercise-Platform---Client-end-(Student)-(UI-Updating)?node-id=1%3A2')}
</div>    
                
<h2>Import sample</h2>
            <a  rel="noopener noreferrer" href="https://oupagile.atlassian.net/browse/EX-340" target="_blank" >https://oupagile.atlassian.net/browse/EX-340</a>

            <h2>Frontend Domains</h2>
            <div>DEV: <a  rel="noopener noreferrer" href="https://dev.exercise.oupchina.com.hk/" target="_blank"
                >https://dev.exercise.oupchina.com.hk/</a></div>
            <div>UAT: <a  rel="noopener noreferrer" href="https://uat.exercise.oupchina.com.hk/" target="_blank"
                >https://uat.exercise.oupchina.com.hk/</a></div>
            <div>PREPROD: <a  rel="noopener noreferrer" href="https://preprod.exercise.oupchina.com" target="_blank"
                >https://preprod.exercise.oupchina.com.hk/</a></div>
            <div>PROD: <a  rel="noopener noreferrer" href="https://exercise.oupchina.com.hk/" target="_blank"
                >https://exercise.oupchina.com.hk/</a></div>
        <h2>Backend Domains</h2>
            <div>DEV: <a  rel="noopener noreferrer" href="https://api.dev.exercise.oupchina.com.hk/" target="_blank"
                >https://api.dev.exercise.oupchina.com.hk/</a></div>
            <div>UAT: <a  rel="noopener noreferrer" href="https://api.uat.exercise.oupchina.com.hk/" target="_blank"
                >https://api.uat.exercise.oupchina.com.hk/</a></div>
            <div>PREPROD: <a  rel="noopener noreferrer" href="https://api.preprod.exercise.oupchina.com.hk/" target="_blank"
                >https://api.preprod.exercise.oupchina.com.hk/</a></div>
            <div>PROD: <a  rel="noopener noreferrer" href="https://api.exercise.oupchina.com.hk/" target="_blank"
                >https://api.exercise.oupchina.com.hk/</a></div>
        <hr/>

        <h2>OLD Expired</h2>
        <a target="figma"  rel="noopener noreferrer" href="https://www.figma.com/file/rCuTtVE3LxIp5PGTK83VBH/Exercise-Platform-(Flow)-WIP?node-id=1853%3A9588" 
            >https://www.figma.com/file/rCuTtVE3LxIp5PGTK83VBH/Exercise-Platform-(Flow)-WIP?node-id=1853%3A9588</a>
            <h1>Figma(old):</h1>
            <h2>Authoring Tool (AT)</h2>
                {PopA("https://www.figma.com/file/4pE6hDupsvdZ1zlVcuvczF/5809-Exercise-Platform---Authoring-Tool")}
            <h2>Teacher</h2>
            {PopA("https://www.figma.com/file/NSV0NRjiB3e4Fa6R4VOI5d/5809-Exercise-Platform--Question-Types-(UI-Updating)?node-id=0%3A1")}
            <a  rel="noopener noreferrer" target="figma" href="https://www.figma.com/file/ENeKihSy4C6CzhXBmOsHnk/5809-Exercise-Platform---Client-end-(Teacher)?node-id=1%3A438" 
                >https://www.figma.com/file/ENeKihSy4C6CzhXBmOsHnk/5809-Exercise-Platform---Client-end-(Teacher)?node-id=1%3A438</a>
            <h2>Student</h2>
            <a  rel="noopener noreferrer" target="figma" href="https://www.figma.com/file/RyUeDRbnwQSZwQPD2W0oyX/5809-Exercise-Platform---Client-end-(Student)?node-id=1%3A2" 
                >https://www.figma.com/file/RyUeDRbnwQSZwQPD2W0oyX/5809-Exercise-Platform---Client-end-(Student)?node-id=1%3A2</a>
            <h2>Exercise and Question Category</h2>
            <a  rel="noopener noreferrer" target="figma" href="https://www.figma.com/file/IKSTQHMv4T6HDvsyLWPSXt/Exercise-Platform-(Internal-AT)---CHM?node-id=0%3A1" 
                >https://www.figma.com/file/IKSTQHMv4T6HDvsyLWPSXt/Exercise-Platform-(Internal-AT)---CHM?node-id=0%3A1</a>
            <hr/>
            <h2>AWS</h2>
            <a  rel="noopener noreferrer" target="figma" href="https://070888223104.signin.aws.amazon.com/console" 
                >https://070888223104.signin.aws.amazon.com/console</a>
            <hr/>     
    </>);
});
