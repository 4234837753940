import React, {useEffect} from 'react';
import queryString from 'query-string';

//import * as RDX from '../AppCard/ReduxAction';
import {ReduxBind} from '../saga/ReduxState';
import {urlPush_Replace} from '../saga/urlPush.saga';
//import { lang_set } from '../saga/lang.saga';

import PageAT from '../AppExPf/PageAT';
import PageAdmin from '../AppExPf/PageAdmin';
import AppRouteEP from '../AppExPFUser/AppRouteEP';
import { _ATRoot, _AdminRoot, _WebRoot, __ATRoot, __AdminRoot } from '../consts/ATConstReact';
import { useMediaCacheEffect } from '../AppExPf/utils/useMediaCache';
import { useUILang } from '../AppExPf/utils/useUILang';
import { useCachesEffect } from '../AppExPf/utils/useCaches';
import PagePatch from './AppDev/PagePatch';
import { routeUse, routesMap } from '../libs/libRouter';
import { logMode } from '../saga/ReduxState';

const PageVersion = ReduxBind( (props) => { return <div><h1>PageVersion</h1></div> });

const stage = process.env.REACT_APP_CFG;

const AppRoute = ReduxBind((props) => {
  const dispatch = props.dispatch;
  
  const [t, uiEn, UILang, setUILang, ut] = useUILang();
  useMediaCacheEffect(props, dispatch);
  useCachesEffect(props, dispatch);

  const logined = 1;//props.isLogined();
  const path = window.location && window.location.pathname;
  const search = window.location && window.location.search;

  useEffect(()=>{
    if(path === _WebRoot){
      //props.dispatch(urlPush_Replace(_ATRoot)); //window.history.pushState({}, "", _WebRoot+"admin");// redurect
      //props.dispatch(urlPush_Replace((stage === 'local')? _WebRoot+'login': _ATRoot)); //window.history.pushState({}, "", _WebRoot+"admin");// redurect
    }
  },[path]);

  useEffect(() => {
    
    if(search){
      const qs = queryString.parse(search);
      //if (logMode()) console.log('AppRoute qs',{qs});
      if(qs.token && !logined){
        return <div>{JSON.stringify(qs)}</div>;
      }
      if(qs.lang){
        const toLang = (qs.lang=== 'zh')?'zh':'en';
        if(toLang !== UILang){
          if (logMode()) console.log('change lang ',{toLang, UILang});
          setUILang(toLang);
        }
        dispatch(urlPush_Replace(path)); //props.dispatch(urlPush_Replace(path)); //props.history.replace({ pathname: path});
      }
    }
  }, [search]);
  

  if(path.startsWith(_WebRoot+'system/info')) return <PageVersion />;
  if(path === (_WebRoot+'patch')) return <PagePatch />;
  if((path === __AdminRoot) || path.startsWith(_AdminRoot)) return <PageAdmin />;

  const route = routesMap(path, [
//    [_WebRoot + 'dev', PageDev],
//    [_WebRoot + 'dev/:dev', PageDev],
  ]);
  const page = (route)? routeUse(route): '';//<PageATBody/>;
  if(page) return  page;

  if((path === __ATRoot) || path.startsWith(_ATRoot) || ((stage === 'local') && (path === '/at'))){
    return <PageAT />;
  }else{ //if(path !== _WebRoot){
    return <AppRouteEP />;
  }
}
);
export default AppRoute;



