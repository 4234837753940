import React, {useMemo, useEffect, } from 'react';

import { _WebRoot, _ATRoot } from '../../consts/ATConstReact';

import { IconList } from '../../consts/ATIconListToUsePoc';
import ScrollContainer from '../_components/CpScrollContainer';
import { debugMode, ReduxBind } from '../../saga/ReduxState';
import PageEXPFLayout from '../PageEXPFLayout';
import { useUILang } from '../../AppExPf/utils/useUILang';

import CpHeaderTitle from '../_components/CpHeaderTitle';

import CpStudentReport from './CpStudentReport';
import { Accordion, Container } from 'react-bootstrap';
import { isMobileScreen } from '../../poc/helper/getWidnowWidth';
import { objEntries, objKeys, toAry, toInt, toObj, toStr } from '../../libs/libType';
import { useCaches } from '../../AppExPf/utils/useCaches';
import { CpAccordionOpenCTA, CpOptionsContainer, } from './PageReportTeacher';
import { _ATMetaTypeCodes } from '../../consts/ATMetaTypes';
import { preJS } from '../../AppExPf/AppUtil';
import { grade2KSYear, RT_meta, useRptCtnOpts, useRptOpts} from './PageReports';
import { formatTimeStampHK, timeStampNowGMT } from '../../libs/libTime';
import { useYearClassItems } from '../EPAssign/TabAssignmentSettingClass';
import { rpt_keyStg } from '../../consts/ATSysQType';

const PageReportStudent = ReduxBind(props => { //base on POC ReportStudent
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();

    const [ cache2d, useGetCache ] = useCaches(props);
	const _subjMetas = useGetCache('subjMetas', '*'); 
    const yc2d = useGetCache('yearClass2d', '*');

    const rptOpts = useRptOpts();
    const {fields, setFields, setField, opts, setOpts} = rptOpts;
    const { yearClassDDI, ymap, clsMap, c, year, grade, cls, stu, } = useStuRptOpts(fields, opts, yc2d, uiEn, t, t2, ut);
    const stuClsInfs = useStuStageClsInfs(yc2d, cls);
    const rptCtnOpts = useRptCtnOpts(fields, opts, _subjMetas, 0, clsMap[c]?.grade, 1, 1, uiEn, t, ut);

    const pgRptStu = useMemo(() => ({yearClassDDI, ymap, clsMap, c, cls}), [yearClassDDI, ymap, clsMap, c, cls]);

    return <PageEXPFLayout sideIdx='report'
        menuTitle={<CpHeaderTitle title={t("sidemenu-reports")} iconPath={IconList.menu.report} animation={true} />} 
        menuActionBar=''//{<CreateExerciseCTA/>} 
        header=''//'headerheaderheader'
        subBody=''//'subBodysubBody'
        footer=''>
        <ScrollContainer className={"PageReportStudent scroll-container h-100 d-flex flex-column w-100 overflow-auto"}>
            <CpStudentReportFilter {...{rptOpts, rptCtnOpts, pgRptStu, stuClsInfs}} />
            <CpStudentReport {...{rptOpts, rptCtnOpts, pgRptStu, stuClsInfs}} />
        </ScrollContainer>
    </PageEXPFLayout>;
});
export default PageReportStudent;

const CpStudentReportFilter = ReduxBind(props => {
    const { dispatch, stuClsInfs, rptOpts, rptCtnOpts, pgRptStu } = props;
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();

    const {yearClassDDI, ymap, clsMap, c, cls, grade} = toObj(pgRptStu);

    const {fields, setFields, setField, opts, setOpts, setRJ, setRMT} = rptOpts;

    const {
        subjDDI, MTypeDDI, MSetDDI, ML1DDI, rptDDI, rpTimeDDI,
        r, j, mt, ms, l1, rti,
        rpt, MSubj, MType, MSet, ML1, rpTime, canKS, hasKS, 
    } = rptCtnOpts;

    useEffect(() => {
        if((j === MSubj) && (c === cls) && (r === rpt)) return;
        setFields(fs => ({...toObj(fs), MSubj:j, cls: c, rpt: r}));
    }, [j, c, r])

    //useEffect(() => { console.log('CpStudentReportFilter Effect', {hasKS, canKS, stuClsInfs, fields, opts,rptCtnOpts, pgRptStu});},
    //[hasKS, canKS, stuClsInfs, fields, opts, rptCtnOpts, pgRptStu]);

    const rViewMeta = (rpt === RT_meta);//1;//    ///report.type.value === ReportType.METADATA

    return <Container fluid={true} className={"CpStudentReportFilter mt-3"}>
        {preJS({stuClsInfs})}
        {preJS(fields)}
        <div className={"CpStudentReportFilter rounded-light border bg-dim-100 mb-3"}>
            <Accordion defaultActiveKey={isMobileScreen() ? "1" : "0"} className={"w-100"}>
                <div className={"d-flex justify-content-between align-items-center gap-3 p-2 semi-bold w-100"}>
                    <CpAccordionOpenCTA eventKey="0">{toStr(rptDDI?.[r]?.t)}</CpAccordionOpenCTA>
                    <span className={"d-flex gap-3"}><CpLastUpdate /></span>
                </div>
                <Accordion.Collapse eventKey="0" className={"bg-transparent"}><div>
                    <hr className={"mt-0 border-dim-400"}/>
                    <div className={"d-flex flex-column flex-xl-row flex-wrap gap-2 justify-content-between px-2 pb-2"}>
                        {/*<OptionsContainer title={t("report-type")} options={studentReportsOptions()}*/}
                        {/*                  className={"option-report"}*/}
                        {/*                  cbItem={handleReport} activeItem={report.type}/>*/}
                        <div className={"d-flex gap-3 flex-wrap justify-content-start w-100"}>
                            <CpOptionsContainer id='DD_subj' title={t("subject")} options={subjDDI} className={"option-subject"}
                                cbItem={setRJ} activeItem={j}/>
                            <CpOptionsContainer id='DD_yearClass' title={t("year-class")} options={yearClassDDI} className={"option-year-class"}
                                cbItem={setField('cls')} activeItem={c}/>
                            <CpOptionsContainer id='DD_rpt' title={t("report-type")} options={rptDDI} className={"option-report"}
                                cbItem={setField('rpt')} activeItem={r}/>
                            {rViewMeta && <CpOptionsContainer id='DD_MType' options={MTypeDDI} cbItem={setRMT} activeItem={mt}
                                title={t("report-metadata")} className={"option-metadata"} />}
                            {rViewMeta && <CpOptionsContainer id='DD_MSet' options={MSetDDI} cbItem={setField('MSet')} activeItem={ms}
                                title={t("report-metadata-set")} className={"option-metadata"} />}
                            {hasKS? <CpOptionsContainer id='DD_rpTime' options={rpTimeDDI} cbItem={setField('rpTime')} activeItem={rti}
                                title={t("period")} className={"option-metadata"} />:''}
                        </div>
                    </div>
                </div></Accordion.Collapse>
            </Accordion>
        </div>
    </Container>
});

export const useStuStageClsInfs = (yc2d, cid) => useMemo(() => {
    const clsInf = findStuClsInf(yc2d, cid);
    if(!clsInf) return [];

    const backYear = grade2KSYear(clsInf?.grade);
    if(backYear < 2) return [clsInf];
    
    const stuYear = toInt(toStr(clsInf?.year).split('-')[0]);
    const Y2dEnts = objEntries(yc2d);

    const stageCls = [clsInf]; 
    for(let y = 1; y < backYear; y++){
        const ypfx = ''+(stuYear - y)+'-'; 
        const [year, ycs] = toAry(Y2dEnts.find(([year, ycs]) => toStr(year).startsWith(ypfx)));
        const [clsId, cInf] = toAry(objEntries(ycs)[0]);
        stageCls.push(clsId && {...cInf, year, clsId});
    }
    stageCls.reverse();
    return stageCls;
}, [yc2d, cid]);


const findStuClsInf = (yc2d, cid) => {
    if(!(yc2d && cid)) return;
    let ret;
    objEntries(yc2d).some(([year, ycs]) => {
        objEntries(ycs).some(([clsId, cInf]) => {
            if(cid === clsId) ret = { ...cInf, year, clsId, };
            return ret;
        });
        return ret;
    });
    return ret;
};

const useStuRptOpts = (fields, opts, yc2d, uiEn, t, t2, ut) => {
    const {year, grade, cls, stu, } = toObj(fields);
    const [yearClassDDI, ymap, clsMap, lastCls] = useYearClassItems(yc2d, uiEn, ut);
    const c = yearClassDDI[cls]? cls: objKeys(yearClassDDI)[0];
    return {
        yearClassDDI, ymap, clsMap, c,  
        year, grade, cls, stu, 
    };
};

export const CpLastUpdate = () => {
    const [t, uiEn, lang, setLang, ut, t2, t3] = useUILang();
    return <span className={"d-flex flex-column align-items-center semi-bold fs-8"}>
        <small className={"text-nowrap"}>{t("last-update")}:</small>
        <small>{formatTimeStampHK(timeStampNowGMT(), "YYYY/MM/DD")}</small>
    </span>
}                  

