import React, {useMemo, useEffect, useState} from "react";
import {Button} from "react-bootstrap";

import CpActionButton from "../_components/CpActionButton";
import { IconList } from "../../consts/ATIconListToUsePoc";
import { isEn2Lang, useCtnLang, useUILang } from "../../AppExPf/utils/useUILang";
import CpIco from "../_components/CpIco";
import { isAry, isObj, nestSolid, objEntries, objKeys, objMap, objMapObj, objVals, strCmp, toAry, toObj, toStr } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
import { mapTMSets, useTickAll, } from "../../AppExPf/ATQtnEdit/PageATQtns";
import { subjMetaCombo } from "../EPAssign/TabAssignmentSettingClass";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { ReduxBind } from "../../saga/ReduxState";
import SharedModalHeader from "../_components/CpSharedModalHeader";
import { CpSettingModalSection, CpSettingModalTabContainer, CpSettingModalTitle } from "../_components/CpSettingModalTabCps";
import CpDropdown from "../_components/CpDropdown";
import CpSharedModal from "../_components/CpSharedModal";
import { MSetGroupType, buildTagMetaEnts } from "../EPLibrary/LayerExerFilter";
import { CpMSRoot } from "../_components/CpEpTypeMSetTrees";

import { _ATMetaTransByCode, _ATMetaTypeByCode, _ATMetaType_DI } from "../../consts/ATMetaTypes";
import { QListMSTs } from "../../AppExPf/components/CpQViewExt";
import { QP_PP } from "../../consts/ATConsts";
import { _ATMetaType_SU } from "../../consts/ATMetaTypes";
import { toUniIdAry } from "../../consts/ATValidate";
import { preJS, autoId, BtnDev } from "../../AppExPf/AppUtil";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { normalizeSMIdStr, splitSMId, splitSMIdPairStr } from "../../consts/ATValidateMetaSet";
import CpMJX from "../_components/CpMJX";

const CpMetaEdBtn = (props) => {
    const [t, uiEn, UILang, setUILang, ut] = useUILang();
    const {variant, onClick} = props;
    return (<span role={"button"} className={`d-flex align-items-center gap-2 fs-8 btn  btn-${variant}`} onClick={onClick}>
        <span><CpIco src={IconList.general.edit}/></span>
        <span>{t("edit")}</span>
    </span>);
};

export const CpAsmQtnMetaEdCTA = ReduxBind (props => { // base on poc MetadataEditorCTA.tsx
    const {dispatch, variant, editAsmQM} = props;

    const {asm, QId, metaDs, reload} = toObj(editAsmQM); 
    const { assignId } = toObj(asm);

    const [ cache2d, useGetCache ] = useCaches(props);
    const subjMetas = useGetCache('subjMetas', '*');

    const [vis, setVis] = useState(0);
    const clickPop = e => { stopEvent(e); setVis(1); };

    const saveASMMetaDs = async (metaDs) => {
        const [res, err] = await asyncApiCallLoad_(dispatch, '/putAssignmentUpdate', {assignId, QId, metaDs});
        reload();
    }  
    const saveMetaDs = (sj, ms) => saveASMMetaDs(ms);

    const subProps = {...props, vis, setVis,
        metaDs, saveMetaDs,
        multiJ:1, subjMetas, };
    return <>
        <CpMetaEdBtn {...{variant, onClick: clickPop}} />
        {vis? <LayerMetadataEditor {...subProps} />: ''}
    </>
});

const CpMetadataEditorCTA = ReduxBind (props => { // base on poc MetadataEditorCTA.tsx
    const {variant, } = props;
    const {setFields, } = props;  
    const fields = toObj(props.fields);

    const [vis, setVis] = useState(0);
    const clickPop = e => { stopEvent(e); setVis(1); };

    const [MSubj, metaDs] = [fields.EMSubjId, toAry(fields.EMetaDisplays)]; 
    const saveMetaDs = (sj, ms) => setFields(fs => ({...toObj(fs), EMSubjId: sj, EMetaDisplays: ms, }));

    const subProps = {...props, vis, setVis, MSubj, metaDs, saveMetaDs};
    return <>
        <CpMetaEdBtn {...{variant, onClick: clickPop}} />
        {vis? <LayerMetadataEditor {...subProps} />: ''}
    </>
});
export default CpMetadataEditorCTA;

export const LayerMetadataEditor = ReduxBind (props => { // base on poc MetadataEditorCTA.tsx
    const {vis, setVis, showEn, multiJ, subjMetas, MSubj, saveMetaDs, tagMSets, useTagMSets } = props;
    const inMetaDs = toAry(props.metaDs);
    const {variant = "exercise-third-btn"} = props;
 
    const [ cache2d, useGetCache ] = useCaches(props);
    const [t, uiEn, UILang, setUILang, ut, t2, t3] = useUILang();
    const [_showEn, ct, filpCtnL, ctnL, setCtnL] = useCtnLang(isEn2Lang(showEn));

    const [ opens, setOpen, setOpens ] = useTickAll([]);
    const [ sj, _setSj ] = useState(MSubj);
    const [ metaDs, setMetaD, setMetaDs, /*tickAll, setTickAll, clearTicks*/] = useTickAll(inMetaDs);

    const subjDDI = useMemo(() => subjMetaCombo(subjMetas, ct), [subjMetas, showEn]);
    const setSj = j => { _setSj(j); };

    useEffect(() => {
        if(vis){
            const _inMetaDs = multiJ? inMetaDs: toAry(inMetaDs).filter(m => m !== MSubj);
            setMetaDs(_inMetaDs);
            setSj(MSubj);
        }
    }, [vis]); 
    useEffect(() => { 
        if(!subjDDI[sj]) setSj(subjDDI[MSubj]? MSubj: objKeys(subjDDI)[0]); 
    }, [subjMetas]);

    const MSubjId = subjDDI[sj]? sj: objKeys(subjDDI)[0];

    const clickVis = (vis, save) => e => { stopEvent(e); 
        if(save && !vis) saveMetaDs(sj, metaDs);
        setVis(vis);
    };

    const SMs = useMemo(() => toUniIdAry(metaDs).map(splitSMId), [metaDs]);
    const MSIds = useMemo(() => toUniIdAry(SMs.map( sm => sm[0])), [SMs]);
    const tagmss = useTagMSets(MSIds);
    const typeTags = useMemo(() => trimAllTagMSets(mapTMSets(SMs, tagmss)[0]), [SMs, tagMSets]); 

    //const typeTags = useMemo(() => trimAllTagMSets(touseTagMSets(useTagMSets, metaDs.map(splitSMIdPairStr))[0]), [tagMSets, metaDs]); 

    const rawallTagMSets = useGetCache('allTagMSets', '*');
    
    //const canTagMSets = useMemo(() => MSetsPerType( getCanTagMSets(rawallTagMSets, multiJ, metaDs, MSubjId)), [rawallTagMSets, multiJ, metaDs, MSubjId, vis]);
    //const typeMSets = useMemo(() => MSetGroupType( buildTagMetaEnts(rawallTagMSets)), [rawallTagMSets]);
    const canTagMSets = useMemo(() => MSetGroupType( buildTagMetaEnts( getCanTagMSets(rawallTagMSets, multiJ, metaDs, MSubjId))), [rawallTagMSets, multiJ, metaDs, MSubjId, vis]);
    
    if(!vis) return '';
    return <CpSharedModal className={"LayerMetadataEditor modal-dialog-90"} show={vis} scrollable={true} 
            header={<SharedModalHeader title={t("edit-taggings")} iconPath={IconList.general.edit} handleClose={clickVis(0, 0)}/>} 
            footer={<div className={"d-flex gap-2 justify-content-center"}>
                <Button onClick={clickVis(0, 0)} variant="gray-body-color w-100 border" > {t("cancel")} </Button>
                <Button onClick={clickVis(0, 1)} variant={`${variant} semi-bold w-100 d-flex gap-2 align-item-center`} > <span>{t("update")}</span> </Button>
            </div>} >
            <CpSettingModalTabContainer gap={3}>
<BtnDev txt="debug Tags">
{preJS({metaDs }, 3)}
{preJS({multiJ, MSubjId, canTagTypes: objKeys(canTagMSets)})}
</BtnDev>
                {multiJ? '': <CpSettingModalSection hideBorder={true}>
                    <div className={"d-flex flex-column flex-md-row gap-2 justify-content-evenly w-100"}>
                        <div className={"d-flex flex-column gap-2 justify-content-start w-100 pe-5"}>
                            <CpSettingModalTitle> {t("subject")} </CpSettingModalTitle>
                            <CpDropdown id="DDsubj" className="w100" items={subjDDI} idx={MSubjId} cb={setSj} />
                        </div>
{/*                        
                        <div className={"d-flex flex-column gap-2 justify-content-start w-100 pe-5"}>
                            <SettingModalTitle>{t("difficulty.title")}</SettingModalTitle>
                            <CpDropdown className="w100" items={{ 0:{t:t("difficulty.easy")}, 1:{t:t("difficulty.medium")}, 2:{t:t("difficulty.hard")} }} idx={diffSel} cb={setDiffSel}/>
                        </div>
*/}
                    </div>
                </CpSettingModalSection>
                }
                <CpEPTagMSetsEdit {...{variant, showEn, t, t2, ct, multiJ, MSubjId, typeTags, canTagMSets, 
                    metaDs, setMetaDs, CpTag:CpEPEditTagMeta, opens, setOpen, setOpens }} />
            </CpSettingModalTabContainer>
        </CpSharedModal>;
});


export const CpEPTagMSetsEdit = (props => {
    const { variant, showEn, t, t2, ct, multiJ, MSubjId, typeTags, canTagMSets, 
        metaDs, setMetaDs, CpTag, 
        opens, setOpen, setOpens} = props;

    const setTickMSet = (msid, MSet) => smid => e => {
        const add = !metaDs.includes(smid);
        const upTicks = add? metaTreeTake(1, smid, MSet, msid): [];
        const downTicks = metaTreeTake(0, smid, MSet, msid);
        setMetaDs(ts => toUniIdAry(add
            ? [...toAry(ts), ...upTicks, ...downTicks]
            : toAry(ts).filter(t => !downTicks.includes(t)) 
        ));
        if(add) setOpens(os => [...os, ...upTicks, ...downTicks]);
        //setMetaD(smid)(e);
    };

    return <>{QListMSTs.map(type => [type, typeTags[type]]).
    filter(([type, mss]) => (mss || nestSolid( canTagMSets[type] )) ).
    map(([type, mss]) => {
        const MSets = canTagMSets[type];
        if(!isObj(MSets)) return '';

        const [browse, setBrowse] = [opens.includes(type), setOpen(type)];
        const canTagMSet = canTagMSets[type];

        return <CpSettingModalSection key={type} hideBorder={true}>
        <CpSettingModalTitle>{t2(_ATMetaTransByCode(type), showEn)}</CpSettingModalTitle>
        <div id={'type_'+type} className={"CpEPTagMSetsEdit d-flex flex-column flex-md-row w-100 gap-2 align-items-end align-items-md-start"}>
            <div className={"d-flex flex-grow-1 border w-100 p-3 gap-2 flex-wrap"}>
                {objEntries(mss).map(([msid, mids]) => {
                    const MSet = toObj(MSets[msid]); 
                    const mms = MSet.metas;
                    if(!mms){
                        //console.error({type, msid, mids});
                        return '';
                    }
                    const clickDel = setTickMSet(msid, MSet);
                    return <div key={autoId()} className={"d-flex flex-wrap gap-2 mb-2"}>
                        {toAry(mids).map(mid => [mid, mms[mid]]).filter(([i, m]) => m)
                            .sort(([i, m], [j, n]) => (m.rnum - n.rnum) || strCmp(i, j))
                            .map(([mid, meta]) => {
                            //return preJS(meta, 3, mid);
                            return <CpTag key={msid+','+mid} {...{...props, mid, meta, msid, clickDel}} /> 
                        })
                    }</div>; 
                }) }
                </div>
                <div className={"d-flex justify-content-end"}>
                    {canTagMSet? <Button onClick={() => setBrowse(!browse)} variant={`${variant} semi-bold w-100 d-flex gap-2 align-item-center text-nowrap`}>
                        {t("browse")}
                    </Button>: ''}
                </div>
            </div>
            {browse && canTagMSet? <div className={"border w-100 p-3"}>
                {objMap(canTagMSets[type], ([msid, MSet]) => {
                    const setTick = setTickMSet(msid, MSet);   
                    return <CpMSRoot key={msid} {...{msid, MSet, ticks:metaDs, setTick, opens, setOpen, ct, tickP:1}} />
                })}
            </div>: ''}
        </CpSettingModalSection>;
    })}</>;
});

export const CpEPEditTagMeta = (props => {
    const {ct, variant, msid, mid, meta, clickDel} = props;
    const id = msid + ',' + mid;
    const title = <CpMJX>{[toStr(meta.LVNum), toStr(ct(meta.nameEn, meta.nameCt))].filter(s=>s).join(' ')}</CpMJX>; //preJS(m);
    return <span {...{key:id, id, 
        className:"CpEPEditTagMeta MID_"+mid+" d-flex gap-2 align-items-center badge-"+variant+" semi-bold rounded"}}>
        <CpActionButton {...{id, title, className:"pe-none"}}/>
        <span {...{id, role: "button", onClick: clickDel(id)}}><CpIco src={IconList.general.remove}/></span>
    </span>;
});

const trimAllTagMSets = (allTMSets) => {
    const ret = objMapObj(allTMSets, ([msid, _mset]) => {
        const {addLogin, dateAdd, addUid, modLogin, dateMod, modUid, MSATSubjIds, 
            MSNameCt, MSNameEn, MSLangEn, MSLangeCt, drpu, dp, tree2, heads, metas,  
            ...mset} = _mset;        
        const ms = objMapObj(metas, ([mid, m]) => {
            const {metaSetId, rnum, pMeta, metaId, pub, nameEn, nameCt, LVNum, ..._m} = m;
            return [mid, {..._m, name:([nameEn, nameCt, LVNum].join(','))} ];
        });
        return [msid, {...mset, metas:ms} ]
    });
    return ret;
};

const mSethasSubjs = (mset, MSubjIds) => {
    const {metaSetId, doReport, MSReports} = toObj(mset);
//    if(!doReport) console.log('mSethasSubjs() nodorpt', {MSubjIds, metaSetId, mset});
    if(!doReport) return;
    const smidSet = new Set(normalizeSMIdStr(MSReports));
    const keep = toAry(MSubjIds).some(j => smidSet.has(j));
//console.log('mSethasSubjs()', {keep, MSubjIds, metaSetId, smidSet, doReport, MSReports, mset});
    return keep;
};
export const mSetHasSubj = (MSet, MSubj) => isObj(MSet) && (MSet.doReport) && normalizeSMIdStr(MSet.MSReports).includes(MSubj);

const hasPubMeta = metas => objVals(metas).some(m => m.pub);

const getCanTagMSets = (rawallTagMSets, multiJ, metaDs, MSubjId) => {
//console.log('getCanTagMSets()', {multiJ, MSubjId, metaDs, rawallTagMSets, });
    const tagged = toUniIdAry(metaDs); //MSubjId, //no MSubjId (Ex-1636)
    const taggedJs = toUniIdAry([MSubjId, ...(multiJ? tagged: [])]);
    const taggedMSid = toUniIdAry(tagged.map(t => toStr(t).split(',')[0]));
//console.log('getCanTagMSets() 2', {tagged, taggedJs, taggedMSid, });
    const Jchk = mset => (mset.MSType === _ATMetaType_DI) 
        || (multiJ && (mset.MSType === _ATMetaType_SU)) 
        || ( mSethasSubjs(mset, taggedJs) );
//console.log('getCanTagMSets() in', {tagged, multiJ, metaDs, MSubjId });
    const retMSet = Object.fromEntries(objEntries(rawallTagMSets).filter(([msid, _mset]) => {
        const { addLogin, dateAdd, addUid, modLogin, dateMod, modUid, 
            MSATSubjIds, MSReports, doReport, MSType, 
            MSNameCt, MSNameEn, MSLangEn, MSLangCt, metaSetId, dp, drpu, MSVer, MSVerId, 
            tree2, heads, metas,   
            } = _mset;        
        if(taggedMSid.includes(metaSetId)) return 'alway keep tagged msets';
        const keep = (drpu === QP_PP) //mset pubed 
            && hasPubMeta(metas) //mset meta pubed
            && Jchk(_mset) //check Subject 
            ;
//        console.log('getCanTagMSets() ms', {keep, metaSetId, drpu, MSType, MSReports, doReport, })
        return keep;
    }));
//console.log('getCanTagMSets() ret', {multiJ, metaDs, MSubjId, tagged, rawallTagMSets, retMSet, });
    return retMSet;
};

const MSetsPerType = MSets => {
    const TMSets = {};
    objEntries(MSets).forEach(([msid, mset]) => {
        const T = mset.MSType;
        if(!T) return;
        TMSets[T] = toObj(TMSets[T]);
        TMSets[T][msid] = mset; 
    });
    return TMSets;
};

const metaTreeTake = (up, smid, mset, msid) => {
    const ret = [smid];
    const ms = toObj(mset?.metas);
    const addUp = mid => {
        const m = ms[mid];
        if(!m)return;
        ret.push(msid+','+mid);
        addUp(m.pMeta);
    };

    const bs = toObj(mset?.MBranchs);
    const addDown = mid => {
        const b = bs[mid];
        if(isAry(b)){ b.forEach(ch => {
            ret.push(msid+','+ch);
            addDown(ch);
        });
    }};

    const mid = toStr(smid).split(',')[1];
    up? addUp(mid): addDown(mid);

    const smids = toUniIdAry(ret);
    return smids;
};

