import React, {useEffect, useMemo, useState} from "react";
import {Container} from "react-bootstrap";


import { useUILang } from "../../AppExPf/utils/useUILang";
import ChartProgressByMonth from "./_component/ChartProgressByMonth";
import { LineBarType, ProgressByMonthType } from "../EPReport/ChartTypeConst";
import ChartAssignmentProgress from "./_component/ChartAssignmentProgress";
import ChartSubjectOverview from "../EPDashboard/ChartSubjectOverview";
//import ChartMetadataProgressV1 from "../../Z_unused/-----ChartMetadataProgressV1";
import CpChartIndicatorGuideline from "./_component/CpChartIndicatorGuideline";

import { debugMode } from "../../saga/ReduxState";
import { useSortList } from "../EPAssign/TabTeacherAssignmentStatus";
import CpDropdown, { ent2DropdownItem } from "../_components/CpDropdown";
import { ReduxBind } from "../../saga/ReduxState";
import { isAry, isObj, toAry, toObj, toStr } from "../../libs/libType";
import { asyncApiCallLoad_ } from "../../libs/awsFuncs";
import { useCaches } from "../../AppExPf/utils/useCaches";
import { BtnPopDev,  preJSSort, autoId } from "../../AppExPf/AppUtil";
import { useTagMSetCache } from "../../AppExPf/utils/useTagMSetCache";
import { toOverData1c,  } from "./data/overData1";
import { toStuAsmData,  } from "./data/stuAsmData";
import ChartMetadataRadialGroup from "./_component/ChartMetadataRadialGroup";
import { ChartMetadataProgressMath } from "./_component/ChartMetadataProgress";
import { toStuMetaData2, toStuMetaDataL2, } from "./data/stuMetaData";
import { expfRoles } from "../../saga/auth.saga";
import { getTeacherName } from "../../AppExPf/ATExerEdit/TabExerContent";
import { stopEvent } from "../../libs/libUI";
import { xapiPost } from "../EPLibrary/PageLibrary";

import { getTransMap } from "../../libs/libTransMap";
import CpMJX from "../_components/CpMJX";
import { chkXRpt, RT_meta } from "./PageReports";
import { usePreLoadRptMsets } from "./CpTeacherReport";
import { rpTime_keyStg, rptPg_asp, rptPg_noa } from "./PageReportTeacher";
import { ChartMetadataKeyStage } from "./_component/ChartMetadataKeyStage";

export const fakeRpt = 0;

const CpStudentReport = ReduxBind(props => { // base on poc StudentReport
	const { dispatch, stuClsInfs} = props;
    
	const [t, uiEn, UILang, setUILang, ut, t2] = useUILang();

	const [ cache2d, useGetCache ] = useCaches(props);
	const [tagMSets, useTagMSets ] = useTagMSetCache(dispatch);
	usePreLoadRptMsets(props.opts, useTagMSets);

	const [isThr, isStt, uRole ] = expfRoles(props);
    const stu = props.userId();
    const stuInf = toObj(props.user());
    const stuName = toStr(ut(stuInf.nameEn, stuInf.name)).trim();
	
	const [rptKeyStage, setRptKeyStage] = useState();
    const [rptDatas, setRptDatas] = useState();

    const {yearClassDDI, ymap} = props.pgRptStu;
    const {fields, setFields, setField, opts, setOpts} = props.rptOpts;
    const {rMSIds, rJOpts, rMSets} = toObj(opts);
    const {/*year, grade, gradeClsIds, */cls} = toObj(fields);
    const year = ymap[cls];

    const {
        subjDDI, MTypeDDI, MSetDDI, ML1DDI, rptDDI, rpTimeDDI,
        r, j, mt, _ms, l1, rti,
        rpt, MSubj, MType, MSet, ML1, rpTime, canKS
    } = props.rptCtnOpts;
    const ms = fakeRpt? 'NCMATH2E_001': props.rptCtnOpts.ms;
    const isKeyStage = (rti === rpTime_keyStg);
	
	const reload = async (caller) => {
        if(!(year && cls && stu)) return;
//console.log('stuClsInfs', {stuClsInfs});
		const clsIds = toAry(stuClsInfs).map(c => c?.clsId).map(i => i);
        const args = { rpt:r, year, cls, stu, MSubj:j, MType:mt, MSet:ms, rptTime:rti, clsIds, caller };
        const [_res, err]  = await asyncApiCallLoad_(dispatch, '/getReports', args);
        const res = fakeRpt? {..._res, ...fakes()}: _res;
        setRptDatas({...rptDatas, ...res?.datas});
		setRptKeyStage({...toObj(rptKeyStage), ...toObj(res?.keyStage)});
		const ro = toObj(res?.rptOpts); 
        setOpts( o => ({...toObj(o), ...ro}) );

		if(chkXRpt(ro, args)) xapiPost(dispatch, 'xRpt', { y:year, g:'', c:cls, stu, r, j, mt, ms, rti, prog}); //EX-1696
	};


    const useSL = useSortList({EName:'asc'});
    const subProps = { ...props, useSL};

    const rViewMeta = (r === RT_meta); //1;// (report.type.value === ReportType.METADATA);
    const rviewOver = !rViewMeta;// (report.type.value === ReportType.OVERVIEW); 

    const [progDDI, prog, setProg, lineBarType] = useProgressMonth(uiEn, t, isStt);

    useEffect(() => { reload('stuRpt'); }, [year, cls, stu, r, j, mt, ms, rti]);


	const thrNames = useGetCache('teachers', '*'); 
    const _getTeacherName = id => getTeacherName(id, thrNames, ut, t);

    const {stuOverDataA_, stuOverDataB, stuAsmData_, clsMetaData_, stuMetaData_, stuMetaDataKS_,
	} = useMemo(() => toObj(rptDatas), [rptDatas]);

    const stuOverDataA = useMemo(() => toOverData1c(stuOverDataA_, stu, stuName), [stuOverDataA_, stuName, stu, uiEn]);
    const stuAsmData = useMemo(() => toStuAsmData(stuAsmData_, stu, _getTeacherName), [stuAsmData_, thrNames]);

    //const stuMetaDataL1 = useMemo(() => toStuMetaDataL1(stuMetaData_, ms, tagMSets, ut), [stuMetaData_, ms, tagMSets, uiEn ]);
    const msets1 = useTagMSets([ms]);
	const stuMetaDataL2 = useMemo(() => toStuMetaDataL2(stuMetaData_, ms, msets1, ut), [stuMetaData_, ms, tagMSets, uiEn ]);
    const stuMetaData2 = useMemo(() => toStuMetaData2(clsMetaData_, stu, j, mt, ms, msets1, ut), [clsMetaData_, j, mt, ms, tagMSets, uiEn ]);
    //const stuMetaData2 = useMemo(() => toStuMetaData2Trim(stuMetaData_, ms, tagMSets, ut), [stuMetaData_, ms, tagMSets, uiEn ]);

    const clickM = e => { stopEvent(e); setField('rpt')(RT_meta); };    

    return <>
    {debugMode()?<div className='flexRowStart' style={{flexWrap:"wrap"}}>
		<BtnPopDev txt="rptKeyStage">{preJSSort({rptKeyStage}, 3)}</BtnPopDev>
        <BtnPopDev txt="stuClsInfs">{preJSSort({stuClsInfs}, 3)}</BtnPopDev>

        <BtnPopDev txt='stuOverDataA_'>{preJSSort(stuOverDataA_,3)}</BtnPopDev> 
        <BtnPopDev txt='stuOverDataA'>{preJSSort(stuOverDataA,3)}</BtnPopDev> 
        <BtnPopDev txt='stuOverDataB'>{preJSSort(stuOverDataB,3)}</BtnPopDev>
        <BtnPopDev txt='stuAsmData'>{preJSSort(stuAsmData,3)}</BtnPopDev>

        <BtnPopDev txt='stuMetaData_'>{preJSSort(stuMetaData_,3)}</BtnPopDev>
        <BtnPopDev txt='stuMetaDataL1'>{'preJSSort(stuMetaDataL1,3)'}</BtnPopDev>
        <BtnPopDev txt='stuMetaData2'>{preJSSort(stuMetaData2,3)}</BtnPopDev>
    </div>:''}    
        {rviewOver && <Container fluid={true} className={"fade-in-container"}>
            <div className="report-result d-flex flex-column flex-lg-row align-items-stretch gap-3">
                {/*Left top*/}
                <div className={`report-progress-col result-col-left result-col-top selected-student-col"`}>
                    <div className={"border rounded-light p-3 overflow-hidden h-100"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}>
                            <h4 className={"m-0"}>{t("report-subject-overview")}</h4>
                        </div>
                        <div className={"flex-grow-1 h-100 d-flex align-items-center"}>
                            {/*Depends on your data and program design, you may no need to show three component in the same time.*/}
                            <ChartSubjectOverview key={autoId()} {...stuOverDataA} />{/*SubjectOverviewByStudentSharedData shared to student*/}
                        </div>
                    </div>
                </div>
{/*Right top*/}
                <div className={`report-progress-col result-col-right result-col-top selected-student-col`}>
                    <div className={"d-flex flex-column border rounded-light h-100 p-3"}>
                        {/*<div className={"d-flex justify-content-between flex-wrap align-items-center mb-3 h-35"}>*/}
						<div className={"d-flex justify-content-between flex-wrap flex-column flex-sm-row align-items-start gap-3 gap-lg-0"}>
                            <h4 className={"m-0"}>{t(isKeyStage? "report-progress-by-year": "report-progress-by-month")}</h4>
                            {/*<span className={"option-progress-by-month"}><CpDropdown className="w100" items={progDDI} idx={prog} cb={setProg}/></span>*/}
							<div className="d-flex flex-column align-items-start align-items-sm-end gap-2">
								<span className={"option-progress-by-month"}><CpDropdown className="w100" items={progDDI} idx={prog} cb={setProg}/></span>
								<CpChartIndicatorGuideline avg={false} passing={false} />
							</div>
                        </div>
                        {<ChartProgressByMonth {...{isKeyStage, rptKeyStage, lineBarType, isStt, ...stuOverDataB}} /> /*ProgressByMonthByStudentSharedData*/ }
                    </div>
                </div>
            </div>
            <div className="report-result d-flex flex-column flex-lg-row align-items-stretch gap-3 mt-3">
{/*Left bottom*/}
				<div className="report-progress-col result-col-left result-col-bottom">
					<div className={"border rounded-light h-100 p-3"}>
						<div className={"d-flex justify-content-between flex-wrap align-items-center mb-3"}></div>
						<div className={"d-flex flex-column gap-2"}>
							<div className={"d-flex gap-2 flex-column flex-xxl-row"}>
								<div className={"w-100"}><CpMJX dynamic={true}>
									<CpDropdown className="w100" items={MTypeDDI} idx={mt} cb={setField('MType')} /*metadataOptions*/ />
								</CpMJX></div>
									<div className={"w-100"}><CpMJX dynamic={true}>
									<CpDropdown className="w100" items={MSetDDI} idx={ms} cb={setField('MSet')} /*items={metadataSetOptions}*/ />
								</CpMJX></div>
							</div>
							<ChartMetadataRadialGroup label={t("report-overall-score-percentage")} 
								{...{...stuMetaDataL2, clickM}} /*MetadataRadialGroupData as MetadataRadialGroupChartType*/ />
							{/*<ChartMetadataRadialGroup label={t("report-overall-average-score-percentage")} key={autoId()} {...stuMetaDataL1} />*/}
						</div>
					</div>
				</div>
{/*Right bottom*/}
                <div className="report-progress-col result-col-right result-col-bottom">
                    <div className={"d-flex flex-column border rounded-light h-100 p-3"}>
                        <div className={"d-flex justify-content-between flex-wrap align-items-center "}>
                            <h4 className={"m-0"}>{t(getTransMap("report-assignments-progress",isStt))}</h4>
                            <CpChartIndicatorGuideline/>
                        </div>
                        <div className={"flow-container"}>
                            {isStt && <ChartAssignmentProgress {...subProps} {...stuAsmData}/>}
                            {/* <AssignmentProgressChart {...AssignmentProgressByStudentSharedData as AssignmentProgressChartType} */}
                        </div>
                    </div>
                </div>
            </div>
        </Container>}
        { (rViewMeta) && <Container fluid={true} className={"fade-in-container h-100"}>
		{isKeyStage
			?<ChartMetadataKeyStage {...{st:stu, stuInf, stuName, rptKeyStage, stuMetaDataKS_, msets: msets1, rptClsKs:0, id:'stu_1cls_keyStg__stuMetaDataKS_'}} />
            :<ChartMetadataProgressMath {...{...stuMetaData2, selectedStudentCase: true, id:'stu_1stu_1yr__stuMetaData2'}} /> /* MetadataProgressByStudentSharedData MetadataProgressChartType*/ }
			{/*<ChartMetadataProgressMath {...{...stuMetaData2, selectedStudentCase: true}}/>*/}
			{/*selectedStudentCase && <MetadataProgressChart {...MetadataProgressByStudentSharedData as MetadataProgressChartType}/>*/}
		</Container>}
    </>
});
export default CpStudentReport;
//mb-3 h-35

export const useProgressMonth = (uiEn, t, isStt = 0) => {
    const progDDI = useMemo(() => ent2DropdownItem([
      [rptPg_noa, t(getTransMap("report-no-of-assignment",isStt))], //, value: 'ProgressByMonthType.NO_OF_ASSIGNMENT'},
      [rptPg_asp, t("report-average-score-percentage")], //, value: 'ProgressByMonthType.AVERAGE_SCORE'}
    ]), [uiEn]);
    const [prog, setProg] = useState(rptPg_noa);
    const lineBarType = (prog === rptPg_noa)? LineBarType.BAR: LineBarType.LINE
    return [progDDI, prog, setProg, lineBarType];
};

const fakes = () => ({
	"rptOpts": {
		"rMSIds": [
			"JUNSCI,JUNSCI",
			"JUNSCI,JUNSCI_1A",
			"JUNSCI,JUNSCI_1A_1",
			"JUNSCI,JUNSCI_1A_1_1.4",
			"JUNSCI,JUNSCI_1A_2",
			"JUNSCI,JUNSCI_1A_2_2.1",
			"JUNSCI,JUNSCI_1A_3",
			"JUNSCI,JUNSCI_1A_3_3.1",
			"JUNSCI,JUNSCI_1A_3_3.2",
			"JUNSCI,JUNSCI_1A_3_3.4",
			"NCMATH2E_001,NCMATH2E",
			"NCMATH2E_001,NCMATH2E_4A",
			"NCMATH2E_001,NCMATH2E_4A_1",
			"NCMATH2E_001,NCMATH2E_4A_2",
			"NCMATH2E_001,NCMATH2E_4A_5",
			"NCMATH2E_001,NCMATH2E_4A_5_5.1",
			"NCMATH2E_001,NCMATH2E_4A_5_5.1_A",
			"NCMATH2E_001,NCMATH2E_4A_5_5.1_B",
			"NMASCI,JUNSCI",
			"__dsephy,DSEPHY",
			"__dsephy,DSEPHY_1",
			"__dsephy,DSEPHY_1_1",
			"__dsephy,DSEPHY_1_1_1.1",
			"__dsephy,DSEPHY_2",
			"__dsephy,DSEPHY_2_2",
			"__dsephy,DSEPHY_2_2_2.3",
			"curr_02,c2",
			"curr_02,c3",
			"dsephy,DSEPHY",
			"dsephy,DSEPHY_1",
			"dsephy,DSEPHY_1_1",
			"dsephy,DSEPHY_1_1_1.1",
			"dsephy,DSEPHY_2",
			"dsephy,DSEPHY_2_2",
			"dsephy,DSEPHY_2_2_2.3",
			"my_skills_01,LATEX",
			"my_skills_01,LATEX_B",
			"my_taxon_01,TAXON1",
			"my_taxon_01,TAXON1_1",
			"my_taxon_01,TAXON1_1_1",
			"my_taxon_01,TAXON1_1_2",
			"my_values_01,V1",
			"my_values_01,V1_1",
			"my_values_01,V1_1_1",
			"my_values_01,V1_1_2",
			"my_values_01,V1_1_3",
			"my_values_01,V1_1_4",
			"my_values_01,V1_1_5",
			"my_values_01,V1_1_6",
			"my_values_01,V1_1_7",
			"my_values_01,V1_1_8",
			"my_values_01,V1_1_9",
			"my_values_01,V1_2",
			"my_values_01,V1_2_1",
			"my_values_01,V1_2_2",
			"other,OTHERS1",
			"other,OTHERS1_1",
			"other,OTHERS1_1_1",
			"pelt,P1_CONNECTIVE_AND",
			"pelt,P1_FIB",
			"pelt,P1_FIB_LV1",
			"pelt,P1_MC",
			"pelt,P1_MC_LV1",
			"pelt,P2_MC",
			"pelt,P2_MC_LV2",
			"pelt,P2_NOUN_PHRASES",
			"pelt,P2_SHORT",
			"pelt,P2_SHORT_LV2",
			"pelt,PELT",
			"pelt,PELT_P1",
			"pelt,PELT_P2",
			"rs_ex_diff,EX_DI_HARD",
			"rs_ex_diff,EX_DI_MEDIUM",
			"rs_grade,SEC",
			"rs_grade,SEC_KS3",
			"rs_grade,SEC_KS3_JunSec",
			"rs_grade,SEC_KS3_JunSec_S1",
			"rs_junsci23,JUNSCI",
			"rs_junsci23,JUNSCI_1A",
			"rs_junsci23,JUNSCI_1A_1",
			"rs_junsci23,JUNSCI_1A_1_1.1",
			"rs_junsci23,JUNSCI_1A_1_1.3",
			"rs_junsci23,JUNSCI_1A_1_1.4",
			"rs_junsci23,JUNSCI_1A_2",
			"rs_junsci23,JUNSCI_1A_2_2.1",
			"rs_junsci23,JUNSCI_1A_3",
			"rs_junsci23,JUNSCI_1A_3_3.1",
			"rs_q_diff,DI_EASY",
			"rs_q_diff,DI_HARD",
			"rs_q_diff,DI_MEDIUM",
			"subjects,MATH_SS",
			"subjects,PHY",
			"subjects,SCIC",
			"subjects,SCII"
		],
		"rJOpts": {
			"subjects,MATH_SS": {
				"nameCt": "高中數學",
				"nameEn": "Senior Secondary Mathematics"
			},
			"subjects,PHY": {
				"nameCt": "物理",
				"nameEn": "Physics"
			},
			"subjects,SCIC": {
				"nameCt": "科學 : 組合科學",
				"nameEn": "Science: Combined Science"
			},
			"subjects,SCII": {
				"nameCt": "初中科學",
				"nameEn": "Junior Science"
			}
		},
		"rMSets": [
			{
				"MSType": "gr",
				"MSNameEn": "Grade",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "rs_grade",
				"MSNameCt": "年級",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "School",
						"nameCt": "學校",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Key Stage",
						"nameCt": "學習階段",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Level",
						"nameCt": "程度",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Grade",
						"nameCt": "年級",
						"metaId": "ID - Level 4"
					}
				],
				"tree2": {
					"SEC": {
						"SEC_KS3": {
							"SEC_KS3_JunSec": {
								"SEC_KS3_JunSec_S1": {},
								"SEC_KS3_JunSec_S3": {},
								"SEC_KS3_JunSec_S2": {}
							}
						},
						"SEC_KS4": {
							"SEC_KS4_SenSec": {
								"SEC_KS3_SenSec_S5": {},
								"SEC_KS3_SenSec_S6": {},
								"SEC_KS3_SenSec_S4": {}
							}
						}
					},
					"KIN": {
						"KIN_ECE": {
							"KIN_ECE_K2": {},
							"KIN_ECE_K3": {},
							"KIN_ECE_N": {},
							"KIN_ECE_K1": {}
						}
					},
					"PRI": {
						"PRI_KS1": {
							"PRI_KS1_JunPri": {
								"PRI_KS1_JunPri_P1": {},
								"PRI_KS1_JunPri_P2": {},
								"PRI_KS1_JunPri_P3": {}
							}
						},
						"PRI_KS2": {
							"PRI_KS2_SenPri": {
								"PRI_KS2_SenPri_P5": {},
								"PRI_KS2_SenPri_P6": {},
								"PRI_KS2_SenPri_P4": {}
							}
						}
					}
				},
				"metas": {
					"SEC_KS3_JunSec_S1": {
						"metaSetId": "rs_grade",
						"nameEn": "S1",
						"rnum": 22,
						"pMeta": "SEC_KS3_JunSec",
						"nameCt": "中一",
						"pub": true,
						"display": 1,
						"lv": 3
					},
					"SEC_KS3_JunSec": {
						"metaSetId": "rs_grade",
						"nameEn": "Junior Secondary",
						"rnum": 21,
						"pMeta": "SEC_KS3",
						"nameCt": "初中",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"SEC_KS3": {
						"metaSetId": "rs_grade",
						"nameEn": "Key Stage 3",
						"rnum": 20,
						"LVNum": "KS3",
						"pMeta": "SEC",
						"nameCt": "第三學習階段",
						"pub": true,
						"display": 1,
						"lv": 1
					},
					"SEC": {
						"metaSetId": "rs_grade",
						"nameEn": "Secondary School",
						"rnum": 19,
						"nameCt": "中學",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "sk",
				"MSReports": "subjects, SCII; subjects, SCIC; subjects, PHY",
				"MSNameEn": "MY Skills 01",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "my_skills_01",
				"MSNameCt": "MY 能力 01",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Skills",
						"nameCt": "能力",
						"metaId": "ID - Level 1"
					}
				],
				"tree2": {
					"LATEX_B": {},
					"LATEX_I": {},
					"CRI_THINK": {},
					"COMM": {},
					"LATEX": {}
				},
				"metas": {
					"LATEX_B": {
						"metaSetId": "my_skills_01",
						"nameEn": "Physics Formulas $$E=mc^2$$",
						"rnum": 5,
						"nameCt": "物理公式 $$E=mc^2$$",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"LATEX": {
						"metaSetId": "my_skills_01",
						"nameEn": "Binomials \\(\\binom{n}{k}\\)",
						"rnum": 4,
						"nameCt": "二項式 \\(\\binom{n}{k}\\)",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "ge",
				"MSNameEn": "pelt",
				"MSLangCt": 0,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "pelt",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Series",
						"nameCt": "",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Grade",
						"nameCt": "",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Learning Focus",
						"nameCt": "",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Type",
						"nameCt": "",
						"metaId": "ID - Level 4"
					},
					{
						"LVNum": "Index - Level 5",
						"nameEn": "Level",
						"nameCt": "",
						"metaId": "ID - Level 5"
					}
				],
				"tree2": {
					"PELT": {
						"PELT_P5": {},
						"PELT_P4": {},
						"PELT_P3": {
							"P3_NOUN_PHRASES": {}
						},
						"PELT_P2": {
							"P2_NOUN_PHRASES": {
								"P2_MC": {
									"P2_MC_LV1": {},
									"P2_MC_LV2": {}
								},
								"P2_SHORT": {
									"P2_SHORT_LV2": {},
									"P2_SHORT_LV1": {}
								}
							},
							"P2_SPT": {
								"P2_LONG": {
									"P2_LONG_LV1": {},
									"P2_LONG_LV2": {}
								},
								"P2_FIB": {
									"P2_FIB_LV2": {},
									"P2_FIB_LV1": {}
								}
							}
						},
						"PELT_P1": {
							"P1_CONNECTIVE_AND": {
								"P1_FIB": {
									"P1_FIB_LV1": {},
									"P1_FIB_LV2": {}
								},
								"P1_MC": {
									"P1_MC_LV2": {},
									"P1_MC_LV1": {},
									"P1_MC_LV3": {}
								}
							},
							"P1_DETERMINERS": {
								"P1_LONG": {
									"P1_LONG_LV1": {},
									"P1_LONG_LV2": {}
								},
								"P1_SHORT": {
									"P1_SHORT_LV2": {},
									"P1_SHORT_LV1": {}
								}
							}
						},
						"PELT_P6": {}
					}
				},
				"metas": {
					"P1_CONNECTIVE_AND": {
						"nameEn": "Connective: and",
						"LVNum": "CONNECTIVE_AND",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P1_FIB_LV1": {
						"nameEn": "Level 1",
						"LVNum": "1",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P1_FIB": {
						"nameEn": "Blank filling",
						"LVNum": "FIB",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P1_MC_LV1": {
						"nameEn": "Level 1",
						"LVNum": "1",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P1_MC": {
						"nameEn": "Multiple Choice",
						"LVNum": "MC",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P2_MC_LV2": {
						"nameEn": "Level 2",
						"LVNum": "2",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P2_MC": {
						"nameEn": "Multiple Choice",
						"LVNum": "MC",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P2_NOUN_PHRASES": {
						"nameEn": "Noun Phases",
						"LVNum": "NOUN_PHRASES",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P2_SHORT_LV2": {
						"nameEn": "Level 2",
						"LVNum": "2",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"P2_SHORT": {
						"nameEn": "Short Question",
						"LVNum": "SHORT",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"PELT_P1": {
						"nameEn": "Primary 1",
						"LVNum": "P1",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"PELT_P2": {
						"nameEn": "Primary 2",
						"LVNum": "P2",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"PELT": {
						"nameEn": "Primary English",
						"LVNum": "PELT",
						"metaSetId": "pelt",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "cu",
				"MSReports": "subjects, SCII; subjects, SCIC; subjects, PHY",
				"MSNameEn": "Curriculum 02",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "curr_02",
				"MSNameCt": "課程 02",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Curr L1",
						"nameCt": "課程 L1",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Curr L2",
						"nameCt": "課程 L2",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Curr L3",
						"nameCt": "課程 L3",
						"metaId": "ID - Level 3"
					}
				],
				"tree2": {
					"c3": {},
					"c4": {},
					"c5": {},
					"c6": {},
					"c1": {},
					"c2": {}
				},
				"metas": {
					"c2": {
						"nameEn": "Painting",
						"rnum": 17,
						"LVNum": "2",
						"nameCt": "上色",
						"metaSetId": "curr_02",
						"pub": 1,
						"display": 1,
						"lv": 0
					},
					"c3": {
						"nameEn": "Printing",
						"rnum": 18,
						"LVNum": "3",
						"nameCt": "印刷",
						"metaSetId": "curr_02",
						"pub": 1,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "tb",
				"MSNameEn": "New Mastering Science",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "NMASCI",
				"MSNameCt": "新編基礎科學",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Series",
						"nameCt": "系列",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Book",
						"nameCt": "冊次",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Unit",
						"nameCt": "課次",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Section",
						"nameCt": "章節",
						"metaId": "ID - Level 4"
					}
				],
				"tree2": {
					"JUNSCI": {
						"JUNSCI_1A": {
							"JUNSCI_1A_1": {
								"JUNSCI_1A_1_1.4": {},
								"JUNSCI_1A_1_1.3": {},
								"JUNSCI_1A_1_1.2": {},
								"JUNSCI_1A_1_1.1": {}
							},
							"JUNSCI_1A_2": {
								"JUNSCI_1A_2_2.5": {},
								"JUNSCI_1A_2_2.6": {},
								"JUNSCI_1A_2_2.3": {},
								"JUNSCI_1A_2_2.4": {},
								"JUNSCI_1A_2_2.1": {},
								"JUNSCI_1A_2_2.2": {}
							},
							"JUNSCI_1A_3": {
								"JUNSCI_1A_3_3.1": {},
								"JUNSCI_1A_3_3.2": {},
								"JUNSCI_1A_3_3.3": {},
								"JUNSCI_1A_3_3.4": {}
							}
						}
					}
				},
				"metas": {
					"JUNSCI": {
						"metaSetId": "NMASCI",
						"nameEn": "Junior Secondary Science Mastering Concepts and Skills",
						"rnum": 2,
						"nameCt": "初中科學基礎概念與技能",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "tb",
				"MSReports": "subjects, MATH_SS; subjects, MATH_JP",
				"MSNameEn": "New Century Mathematics (2nd Edition) S4-S6",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "NCMATH2E_001",
				"MSNameCt": "新世代數學（第二版）S4-S6",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Series",
						"nameCt": "系列",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Book",
						"nameCt": "本書",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Chapter",
						"nameCt": "章節",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Section",
						"nameCt": "部份",
						"metaId": "ID - Level 4"
					},
					{
						"LVNum": "Index - Level 5",
						"nameEn": "Sub-Section",
						"nameCt": "次部份",
						"metaId": "ID - Level 5"
					}
				],
				"tree2": {
					"NCMATH2E": {
						"NCMATH2E_4A": {
							"NCMATH2E_4A_1": {
								"NCMATH2E_4A_1_1.1": {
									"NCMATH2E_4A_1_1.1_D": {},
									"NCMATH2E_4A_1_1.1_A": {},
									"NCMATH2E_4A_1_1.1_B": {},
									"NCMATH2E_4A_1_1.1_C": {}
								},
								"NCMATH2E_4A_1_1.3": {
									"NCMATH2E_4A_1_1.3_B": {},
									"NCMATH2E_4A_1_1.3_C": {},
									"NCMATH2E_4A_1_1.3_A": {}
								},
								"NCMATH2E_4A_1_1.2": {
									"NCMATH2E_4A_1_1.2_A": {},
									"NCMATH2E_4A_1_1.2_B": {}
								}
							},
							"NCMATH2E_4A_2": {
								"NCMATH2E_4A_2_2.1": {
									"NCMATH2E_4A_2_2.1_F": {},
									"NCMATH2E_4A_2_2.1_A": {},
									"NCMATH2E_4A_2_2.1_B": {},
									"NCMATH2E_4A_2_2.1_C": {},
									"NCMATH2E_4A_2_2.1_D": {},
									"NCMATH2E_4A_2_2.1_E": {}
								},
								"NCMATH2E_4A_2_2.0": {},
								"NCMATH2E_4A_2_2.2": {}
							},
							"NCMATH2E_4A_3": {
								"NCMATH2E_4A_3_3.1": {},
								"NCMATH2E_4A_3_3.0": {},
								"NCMATH2E_4A_3_3.7": {
									"NCMATH2E_4A_3_3.7_B": {},
									"NCMATH2E_4A_3_3.7_A": {}
								},
								"NCMATH2E_4A_3_3.6": {},
								"NCMATH2E_4A_3_3.5": {},
								"NCMATH2E_4A_3_3.4": {},
								"NCMATH2E_4A_3_3.3": {},
								"NCMATH2E_4A_3_3.2": {}
							},
							"NCMATH2E_4A_4": {
								"NCMATH2E_4A_4_4.1": {},
								"NCMATH2E_4A_4_4.3": {
									"NCMATH2E_4A_4_4.3_A": {},
									"NCMATH2E_4A_4_4.3_C": {},
									"NCMATH2E_4A_4_4.3_B": {}
								},
								"NCMATH2E_4A_4_4.2": {
									"NCMATH2E_4A_4_4.2_B": {},
									"NCMATH2E_4A_4_4.2_A": {},
									"NCMATH2E_4A_4_4.2_C": {}
								}
							},
							"NCMATH2E_4A_5": {
								"NCMATH2E_4A_5_5.1": {
									"NCMATH2E_4A_5_5.1_A": {},
									"NCMATH2E_4A_5_5.1_B": {}
								},
								"NCMATH2E_4A_5_5.2": {
									"NCMATH2E_4A_5_5.2_D": {},
									"NCMATH2E_4A_5_5.2_C": {},
									"NCMATH2E_4A_5_5.2_B": {},
									"NCMATH2E_4A_5_5.2_A": {}
								}
							},
							"NCMATH2E_4A_6": {
								"NCMATH2E_4A_6_6.1": {
									"NCMATH2E_4A_6_6.1_A": {},
									"NCMATH2E_4A_6_6.1_B": {},
									"NCMATH2E_4A_6_6.1_C": {}
								},
								"NCMATH2E_4A_6_6.0": {},
								"NCMATH2E_4A_6_6.3": {
									"NCMATH2E_4A_6_6.3_B": {},
									"NCMATH2E_4A_6_6.3_A": {}
								},
								"NCMATH2E_4A_6_6.2": {}
							}
						},
						"NCMATH2E_4B": {
							"NCMATH2E_4B_7": {
								"NCMATH2E_4B_7_7.2": {
									"NCMATH2E_4B_7_7.2_A": {},
									"NCMATH2E_4B_7_7.2_B": {}
								},
								"NCMATH2E_4B_7_7.1": {
									"NCMATH2E_4B_7_7.1_A": {},
									"NCMATH2E_4B_7_7.1_B": {},
									"NCMATH2E_4B_7_7.1_C": {}
								},
								"NCMATH2E_4B_7_7.0": {}
							},
							"NCMATH2E_4B_8": {
								"NCMATH2E_4B_8_8.3": {
									"NCMATH2E_4B_8_8.3_B": {},
									"NCMATH2E_4B_8_8.3_A": {}
								},
								"NCMATH2E_4B_8_8.4": {
									"NCMATH2E_4B_8_8.4_A": {},
									"NCMATH2E_4B_8_8.4_B": {}
								},
								"NCMATH2E_4B_8_8.5": {
									"NCMATH2E_4B_8_8.5_C": {},
									"NCMATH2E_4B_8_8.5_B": {},
									"NCMATH2E_4B_8_8.5_A": {}
								},
								"NCMATH2E_4B_8_8.1": {
									"NCMATH2E_4B_8_8.1_C": {},
									"NCMATH2E_4B_8_8.1_B": {},
									"NCMATH2E_4B_8_8.1_A": {}
								},
								"NCMATH2E_4B_8_8.2": {}
							},
							"NCMATH2E_4B_9": {
								"NCMATH2E_4B_9_9.0": {},
								"NCMATH2E_4B_9_9.3": {
									"NCMATH2E_4B_9_9.3_B": {},
									"NCMATH2E_4B_9_9.3_A": {}
								},
								"NCMATH2E_4B_9_9.1": {},
								"NCMATH2E_4B_9_9.2": {
									"NCMATH2E_4B_9_9.2_A": {},
									"NCMATH2E_4B_9_9.2_B": {}
								}
							},
							"NCMATH2E_4B_12": {
								"NCMATH2E_4B_12_12.0": {},
								"NCMATH2E_4B_12_12.1": {
									"NCMATH2E_4B_12_12.1_A": {},
									"NCMATH2E_4B_12_12.1_B": {},
									"NCMATH2E_4B_12_12.1_C": {},
									"NCMATH2E_4B_12_12.1_D": {},
									"NCMATH2E_4B_12_12.1_E": {}
								},
								"NCMATH2E_4B_12_12.2": {
									"NCMATH2E_4B_12_12.2_B": {},
									"NCMATH2E_4B_12_12.2_A": {}
								},
								"NCMATH2E_4B_12_12.3": {
									"NCMATH2E_4B_12_12.3_A": {},
									"NCMATH2E_4B_12_12.3_B": {},
									"NCMATH2E_4B_12_12.3_C": {}
								},
								"NCMATH2E_4B_12_12.4": {
									"NCMATH2E_4B_12_12.4_A": {},
									"NCMATH2E_4B_12_12.4_B": {},
									"NCMATH2E_4B_12_12.4_C": {}
								}
							},
							"NCMATH2E_4B_11": {
								"NCMATH2E_4B_11_11.5": {},
								"NCMATH2E_4B_11_11.4": {},
								"NCMATH2E_4B_11_11.3": {
									"NCMATH2E_4B_11_11.3_B": {},
									"NCMATH2E_4B_11_11.3_A": {},
									"NCMATH2E_4B_11_11.3_C": {}
								},
								"NCMATH2E_4B_11_11.2": {},
								"NCMATH2E_4B_11_11.1": {
									"NCMATH2E_4B_11_11.1_B": {},
									"NCMATH2E_4B_11_11.1_A": {}
								}
							},
							"NCMATH2E_4B_10": {
								"NCMATH2E_4B_10_10.3": {
									"NCMATH2E_4B_10_10.3_A": {},
									"NCMATH2E_4B_10_10.3_B": {}
								},
								"NCMATH2E_4B_10_10.4": {
									"NCMATH2E_4B_10_10.4_B": {},
									"NCMATH2E_4B_10_10.4_A": {}
								},
								"NCMATH2E_4B_10_10.1": {
									"NCMATH2E_4B_10_10.1_A": {},
									"NCMATH2E_4B_10_10.1_B": {}
								},
								"NCMATH2E_4B_10_10.2": {
									"NCMATH2E_4B_10_10.2_A": {},
									"NCMATH2E_4B_10_10.2_B": {},
									"NCMATH2E_4B_10_10.2_C": {}
								},
								"NCMATH2E_4B_10_10.0": {}
							}
						}
					}
				},
				"metas": {
					"NCMATH2E_4A_1": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "Number Systems",
						"rnum": 4,
						"LVNum": "1",
						"pMeta": "NCMATH2E_4A",
						"nameCt": "數系",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"NCMATH2E_4A_2": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "Equations of Straight Lines",
						"rnum": 17,
						"LVNum": "2",
						"pMeta": "NCMATH2E_4A",
						"nameCt": "直線的方程",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"NCMATH2E_4A_5_5.1_A": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "The Graph of \\(y=ax^2\\)",
						"rnum": 50,
						"LVNum": "A",
						"pMeta": "NCMATH2E_4A_5_5.1",
						"nameCt": "\\(y=ax^2\\) 的圖像",
						"pub": 1,
						"display": 1,
						"lv": 4
					},
					"NCMATH2E_4A_5_5.1_B": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "The Graph of \\(y=ax^2+bx+c\\)",
						"rnum": 51,
						"LVNum": "B",
						"pMeta": "NCMATH2E_4A_5_5.1",
						"nameCt": "\\(y=ax^2+bx+c\\) 的圖像",
						"pub": 1,
						"display": 1,
						"lv": 4
					},
					"NCMATH2E_4A_5_5.1": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "Features of the Graphs of Quadratic Functions",
						"rnum": 49,
						"LVNum": "5.1",
						"pMeta": "NCMATH2E_4A_5",
						"nameCt": "二次函數圖像的特徵",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"NCMATH2E_4A_5": {
						"metaSetId": "NCMATH2E_001",
						"nameEn": "Quadratic Functions",
						"rnum": 48,
						"LVNum": "5",
						"pMeta": "NCMATH2E_4A",
						"nameCt": "二次函數",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"NCMATH2E_4A": {
						"rnum": 3,
						"LVNum": "4A",
						"pMeta": "NCMATH2E",
						"metaSetId": "NCMATH2E_001",
						"pub": 1,
						"display": 1,
						"lv": 1
					},
					"NCMATH2E": {
						"nameEn": "New Century Mathematics (2nd Edition) S4-S6",
						"rnum": 2,
						"nameCt": "新世代數學（第二版）S4-S6",
						"metaSetId": "NCMATH2E_001",
						"pub": 1,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "va",
				"MSReports": "subjects, SCII; subjects, SCIC; subjects, PHY",
				"MSNameEn": "MY Values 01",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "my_values_01",
				"MSNameCt": "MY 定值 01",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Value L1",
						"nameCt": "定值 L1",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Value L2",
						"nameCt": "定值 L2",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Value L3",
						"nameCt": "定值 L3",
						"metaId": "ID - Level 3"
					}
				],
				"tree2": {
					"V6": {
						"V6_1": {
							"V6_1_2": {},
							"V6_1_1": {}
						}
					},
					"V7": {
						"V7_1": {
							"V7_1_2": {},
							"V7_1_1": {}
						}
					},
					"V1": {
						"V1_2": {
							"V1_2_2": {},
							"V1_2_1": {}
						},
						"V1_1": {
							"V1_1_1": {},
							"V1_1_9": {},
							"V1_1_8": {},
							"V1_1_7": {},
							"V1_1_6": {},
							"V1_1_5": {},
							"V1_1_4": {},
							"V1_1_3": {},
							"V1_1_2": {}
						}
					},
					"V2": {
						"V2_2": {
							"V2_2_1": {},
							"V2_2_2": {}
						},
						"V2_1": {
							"V2_1_2": {},
							"V2_1_1": {}
						}
					},
					"V3": {
						"V3_1": {
							"V3_1_2": {},
							"V3_1_1": {}
						}
					},
					"V4": {
						"V4_1": {
							"V4_1_2": {},
							"V4_1_1": {}
						}
					},
					"V5": {
						"V5_1": {
							"V5_1_1": {},
							"V5_1_2": {}
						}
					}
				},
				"metas": {
					"V1_1_1": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-1",
						"rnum": 4,
						"LVNum": "1.1.1",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-1",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_2": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-2",
						"rnum": 5,
						"LVNum": "1.1.2",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-2",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_3": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-3",
						"rnum": 6,
						"LVNum": "1.1.3",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-3",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_4": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-4",
						"rnum": 7,
						"LVNum": "1.1.4",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-4",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_5": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-5",
						"rnum": 8,
						"LVNum": "1.1.5",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-5",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_6": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-6",
						"rnum": 9,
						"LVNum": "1.1.6",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-6",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_7": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-7",
						"rnum": 10,
						"LVNum": "1.1.7",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-7",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_8": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-8",
						"rnum": 11,
						"LVNum": "1.1.8",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-8",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1_9": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1-9",
						"rnum": 12,
						"LVNum": "1.1.9",
						"pMeta": "V1_1",
						"nameCt": "定值 1-1-9",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"V1_1": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-1",
						"rnum": 3,
						"LVNum": "1.1",
						"pMeta": "V1",
						"nameCt": "定值 1-1",
						"pub": true,
						"display": 1,
						"lv": 1
					},
					"V1_2_1": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-2-1",
						"rnum": 14,
						"LVNum": "1.2.1",
						"pMeta": "V1_2",
						"nameCt": "定值 1-2-1",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"V1_2_2": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-2-2",
						"rnum": 15,
						"LVNum": "1.2.2",
						"pMeta": "V1_2",
						"nameCt": "定值 1-2-2",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"V1_2": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1-2",
						"rnum": 13,
						"LVNum": "1.2",
						"pMeta": "V1",
						"nameCt": "定值 1-2",
						"pub": 1,
						"display": 1,
						"lv": 1
					},
					"V1": {
						"metaSetId": "my_values_01",
						"nameEn": "Value 1",
						"rnum": 2,
						"LVNum": "1",
						"nameCt": "定值 1",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "di",
				"MSNameEn": "Exercise Difficulty",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "rs_ex_diff",
				"MSNameCt": "練習難度",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Difficulty",
						"nameCt": "難度",
						"metaId": "ID - Level 1"
					}
				],
				"tree2": {
					"EX_DI_EASY": {},
					"EX_DI_HARD": {},
					"EX_DI_MEDIUM": {}
				},
				"metas": {
					"EX_DI_HARD": {
						"nameEn": "Hard",
						"rnum": 4,
						"nameCt": "困難",
						"metaSetId": "rs_ex_diff",
						"pub": 1,
						"display": 1,
						"lv": 0
					},
					"EX_DI_MEDIUM": {
						"nameEn": "Medium",
						"rnum": 3,
						"nameCt": "中等",
						"metaSetId": "rs_ex_diff",
						"pub": 1,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "ot",
				"MSNameEn": "Others",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "other",
				"MSNameCt": "其他",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Others L1",
						"nameCt": "其他 L1",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Others L2",
						"nameCt": "其他 L2",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Others L3",
						"nameCt": "其他 L3",
						"metaId": "ID - Level 3"
					}
				],
				"tree2": {
					"OTHERS1": {
						"OTHERS1_1": {
							"OTHERS1_1_1": {},
							"OTHERS1_1_2": {}
						},
						"OTHERS1_2": {
							"OTHERS1_2_2": {},
							"OTHERS1_2_1": {}
						}
					},
					"OTHERS2": {
						"OTHERS2_2": {
							"OTHERS2_2_1": {},
							"OTHERS2_2_2": {}
						},
						"OTHERS2_1": {
							"OTHERS2_1_1": {},
							"OTHERS2_1_2": {}
						}
					}
				},
				"metas": {
					"OTHERS1_1_1": {
						"metaSetId": "other",
						"nameEn": "Others 1-1-1",
						"rnum": 4,
						"pMeta": "OTHERS1_1",
						"nameCt": "其他 1-1-1",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"OTHERS1_1": {
						"metaSetId": "other",
						"nameEn": "Others 1-1",
						"rnum": 3,
						"pMeta": "OTHERS1",
						"nameCt": "其他 1-1",
						"pub": true,
						"display": 1,
						"lv": 1
					},
					"OTHERS1": {
						"metaSetId": "other",
						"nameEn": "Others 1",
						"rnum": 2,
						"nameCt": "其他 1",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "tb",
				"MSReports": "subjects, PHY",
				"MSNameEn": "Physics at Work for HKDSE",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "__dsephy",
				"MSNameCt": "香港中學文憑生活ç©ç",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Series",
						"nameCt": "ç³»å",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Book",
						"nameCt": "å",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Chapter",
						"nameCt": "èª²",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Unit",
						"nameCt": "ç¯",
						"metaId": "ID - Level 4"
					}
				],
				"tree2": {
					"DSEPHY": {
						"DSEPHY_0": {},
						"DSEPHY_E2": {
							"DSEPHY_E2_3": {
								"DSEPHY_E2_3_3.1": {},
								"DSEPHY_E2_3_3.2": {}
							},
							"DSEPHY_E2_2": {
								"DSEPHY_E2_2_2.1": {},
								"DSEPHY_E2_2_2.2": {},
								"DSEPHY_E2_2_2.3": {}
							},
							"DSEPHY_E2_1": {
								"DSEPHY_E2_1_1.1": {},
								"DSEPHY_E2_1_1.2": {}
							}
						},
						"DSEPHY_1": {
							"DSEPHY_1_1": {
								"DSEPHY_1_1_1.1": {},
								"DSEPHY_1_1_1.2": {}
							},
							"DSEPHY_1_2": {
								"DSEPHY_1_2_2.1": {}
							},
							"DSEPHY_1_3": {
								"DSEPHY_1_3_3.1": {},
								"DSEPHY_1_3_3.2": {}
							},
							"DSEPHY_1_4": {
								"DSEPHY_1_4_4.1": {},
								"DSEPHY_1_4_4.2": {}
							},
							"DSEPHY_1_5": {
								"DSEPHY_1_5_5.1": {},
								"DSEPHY_1_5_5.2": {}
							}
						},
						"DSEPHY_E1": {
							"DSEPHY_E1_3": {
								"DSEPHY_E1_3_3.4": {},
								"DSEPHY_E1_3_3.2": {},
								"DSEPHY_E1_3_3.3": {},
								"DSEPHY_E1_3_3.1": {}
							},
							"DSEPHY_E1_2": {
								"DSEPHY_E1_2_2.1": {},
								"DSEPHY_E1_2_2.2": {},
								"DSEPHY_E1_2_2.3": {}
							},
							"DSEPHY_E1_1": {
								"DSEPHY_E1_1_1.4": {},
								"DSEPHY_E1_1_1.2": {},
								"DSEPHY_E1_1_1.3": {},
								"DSEPHY_E1_1_1.1": {}
							}
						},
						"DSEPHY_2": {
							"DSEPHY_2_5": {
								"DSEPHY_2_5_5.2": {},
								"DSEPHY_2_5_5.1": {}
							},
							"DSEPHY_2_6": {
								"DSEPHY_2_6_6.3": {},
								"DSEPHY_2_6_6.2": {},
								"DSEPHY_2_6_6.4": {},
								"DSEPHY_2_6_6.1": {}
							},
							"DSEPHY_2_10": {
								"DSEPHY_2_10_10.2": {},
								"DSEPHY_2_10_10.1": {}
							},
							"DSEPHY_2_7": {
								"DSEPHY_2_7_7.2": {},
								"DSEPHY_2_7_7.1": {}
							},
							"DSEPHY_2_8": {
								"DSEPHY_2_8_8.2": {},
								"DSEPHY_2_8_8.1": {}
							},
							"DSEPHY_2_1": {
								"DSEPHY_2_1_1.4": {},
								"DSEPHY_2_1_1.2": {},
								"DSEPHY_2_1_1.3": {},
								"DSEPHY_2_1_1.1": {}
							},
							"DSEPHY_2_2": {
								"DSEPHY_2_2_2.2": {},
								"DSEPHY_2_2_2.3": {},
								"DSEPHY_2_2_2.4": {},
								"DSEPHY_2_2_2.1": {}
							},
							"DSEPHY_2_3": {
								"DSEPHY_2_3_3.6": {},
								"DSEPHY_2_3_3.7": {},
								"DSEPHY_2_3_3.4": {},
								"DSEPHY_2_3_3.5": {},
								"DSEPHY_2_3_3.2": {},
								"DSEPHY_2_3_3.3": {},
								"DSEPHY_2_3_3.1": {}
							},
							"DSEPHY_2_4": {
								"DSEPHY_2_4_4.2": {},
								"DSEPHY_2_4_4.3": {},
								"DSEPHY_2_4_4.1": {}
							},
							"DSEPHY_2_9": {
								"DSEPHY_2_9_9.3": {},
								"DSEPHY_2_9_9.2": {},
								"DSEPHY_2_9_9.1": {}
							}
						},
						"DSEPHY_3B": {
							"DSEPHY_3B_5": {
								"DSEPHY_3B_5_5.4": {},
								"DSEPHY_3B_5_5.3": {},
								"DSEPHY_3B_5_5.2": {},
								"DSEPHY_3B_5_5.1": {}
							},
							"DSEPHY_3B_4": {
								"DSEPHY_3B_4_4.2": {},
								"DSEPHY_3B_4_4.1": {},
								"DSEPHY_3B_4_4.4": {},
								"DSEPHY_3B_4_4.3": {}
							},
							"DSEPHY_3B_7": {
								"DSEPHY_3B_7_7.2": {},
								"DSEPHY_3B_7_7.1": {},
								"DSEPHY_3B_7_7.3": {}
							},
							"DSEPHY_3B_6": {
								"DSEPHY_3B_6_6.2": {},
								"DSEPHY_3B_6_6.1": {},
								"DSEPHY_3B_6_6.4": {},
								"DSEPHY_3B_6_6.3": {}
							}
						},
						"DSEPHY_E4": {
							"DSEPHY_E4_1": {
								"DSEPHY_E4_1_1.3": {},
								"DSEPHY_E4_1_1.1": {},
								"DSEPHY_E4_1_1.2": {}
							},
							"DSEPHY_E4_3": {
								"DSEPHY_E4_3_3.3": {},
								"DSEPHY_E4_3_3.4": {},
								"DSEPHY_E4_3_3.1": {},
								"DSEPHY_E4_3_3.2": {}
							},
							"DSEPHY_E4_2": {
								"DSEPHY_E4_2_2.3": {},
								"DSEPHY_E4_2_2.1": {},
								"DSEPHY_E4_2_2.2": {}
							}
						},
						"DSEPHY_3A": {
							"DSEPHY_3A_2": {
								"DSEPHY_3A_2_2.1": {},
								"DSEPHY_3A_2_2.2": {}
							},
							"DSEPHY_3A_1": {
								"DSEPHY_3A_1_1.1": {},
								"DSEPHY_3A_1_1.2": {}
							},
							"DSEPHY_3A_3": {
								"DSEPHY_3A_3_3.1": {},
								"DSEPHY_3A_3_3.2": {},
								"DSEPHY_3A_3_3.3": {},
								"DSEPHY_3A_3_3.4": {}
							}
						},
						"DSEPHY_E3": {
							"DSEPHY_E3_2": {
								"DSEPHY_E3_2_2.2": {},
								"DSEPHY_E3_2_2.1": {}
							},
							"DSEPHY_E3_1": {
								"DSEPHY_E3_1_1.2": {},
								"DSEPHY_E3_1_1.3": {},
								"DSEPHY_E3_1_1.1": {}
							},
							"DSEPHY_E3_4": {
								"DSEPHY_E3_4_4.2": {},
								"DSEPHY_E3_4_4.1": {}
							},
							"DSEPHY_E3_3": {
								"DSEPHY_E3_3_3.2": {},
								"DSEPHY_E3_3_3.1": {}
							}
						},
						"DSEPHY_4": {
							"DSEPHY_4_3": {
								"DSEPHY_4_3_3.1": {},
								"DSEPHY_4_3_3.2": {}
							},
							"DSEPHY_4_4": {
								"DSEPHY_4_4_4.1": {},
								"DSEPHY_4_4_4.3": {},
								"DSEPHY_4_4_4.2": {}
							},
							"DSEPHY_4_5": {
								"DSEPHY_4_5_5.1": {},
								"DSEPHY_4_5_5.2": {}
							},
							"DSEPHY_4_6": {
								"DSEPHY_4_6_6.1": {},
								"DSEPHY_4_6_6.3": {},
								"DSEPHY_4_6_6.2": {}
							},
							"DSEPHY_4_1": {
								"DSEPHY_4_1_1.1": {},
								"DSEPHY_4_1_1.3": {},
								"DSEPHY_4_1_1.2": {}
							},
							"DSEPHY_4_2": {
								"DSEPHY_4_2_2.5": {},
								"DSEPHY_4_2_2.4": {},
								"DSEPHY_4_2_2.1": {},
								"DSEPHY_4_2_2.3": {},
								"DSEPHY_4_2_2.2": {}
							},
							"DSEPHY_4_7": {
								"DSEPHY_4_7_7.1": {},
								"DSEPHY_4_7_7.3": {},
								"DSEPHY_4_7_7.2": {}
							},
							"DSEPHY_4_8": {
								"DSEPHY_4_8_8.1": {},
								"DSEPHY_4_8_8.2": {}
							}
						},
						"DSEPHY_5": {
							"DSEPHY_5_2": {
								"DSEPHY_5_2_2.2": {},
								"DSEPHY_5_2_2.1": {},
								"DSEPHY_5_2_2.3": {}
							},
							"DSEPHY_5_3": {
								"DSEPHY_5_3_3.2": {},
								"DSEPHY_5_3_3.1": {},
								"DSEPHY_5_3_3.3": {}
							},
							"DSEPHY_5_1": {
								"DSEPHY_5_1_1.2": {},
								"DSEPHY_5_1_1.1": {},
								"DSEPHY_5_1_1.3": {}
							}
						}
					}
				},
				"metas": {
					"DSEPHY_1_1_1.1": {
						"metaSetId": "__dsephy",
						"nameEn": "Measuring temperature",
						"rnum": 6,
						"LVNum": "1.1",
						"pMeta": "DSEPHY_1_1",
						"nameCt": "éåº¦æ¸©åº¦",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"DSEPHY_1_1": {
						"metaSetId": "__dsephy",
						"nameEn": "Temperature and Thermometers-1",
						"rnum": 5,
						"LVNum": "1",
						"pMeta": "DSEPHY_1",
						"nameCt": "æ¸©åº¦èæ¸©åº¦è¨-1",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"DSEPHY_1": {
						"metaSetId": "__dsephy",
						"nameEn": "Heat and Gases",
						"rnum": 4,
						"LVNum": "1",
						"pMeta": "DSEPHY",
						"nameCt": "ç±åæ°£é«",
						"pub": 1,
						"display": 1,
						"lv": 1
					},
					"DSEPHY_2_2_2.3": {
						"metaSetId": "__dsephy",
						"nameEn": "Equations of uniformly accelerated motion",
						"rnum": 28,
						"LVNum": "2.3",
						"pMeta": "DSEPHY_2_2",
						"nameCt": "å»å ééåæ¹ç¨",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"DSEPHY_2_2": {
						"metaSetId": "__dsephy",
						"nameEn": "Straight-line Motion",
						"rnum": 25,
						"LVNum": "2",
						"pMeta": "DSEPHY_2",
						"nameCt": "ç´ç·éå",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"DSEPHY_2": {
						"metaSetId": "__dsephy",
						"nameEn": "Force and Motion",
						"rnum": 19,
						"LVNum": "2",
						"pMeta": "DSEPHY",
						"nameCt": "ååéå",
						"pub": 1,
						"display": 1,
						"lv": 1
					},
					"DSEPHY": {
						"nameEn": "Physics at Work for HKDSE",
						"rnum": 2,
						"nameCt": "é¦æ¸¯ä¸­å­¸ææçæ´»èç©ç",
						"metaSetId": "__dsephy",
						"pub": 1,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "tb",
				"MSReports": "subjects, SCII",
				"MSNameEn": "Junior Secondary Science Mastering Concepts and Skills_2023",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "rs_junsci23",
				"MSNameCt": "åä¸­ç§å­¸åºç¤æ¦å¿µèæè½_2023",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Series",
						"nameCt": "ç³»å",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Book",
						"nameCt": "åæ¬¡",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Unit",
						"nameCt": "èª²æ¬¡",
						"metaId": "ID - Level 3"
					},
					{
						"LVNum": "Index - Level 4",
						"nameEn": "Section",
						"nameCt": "ç« ç¯",
						"metaId": "ID - Level 4"
					}
				],
				"tree2": {
					"JUNSCI": {
						"JUNSCI_1B": {
							"JUNSCI_1B_1": {}
						},
						"JUNSCI_1A": {
							"JUNSCI_1A_1": {
								"JUNSCI_1A_1_1.4": {},
								"JUNSCI_1A_1_1.3": {},
								"JUNSCI_1A_1_1.2": {},
								"JUNSCI_1A_1_1.1": {}
							},
							"JUNSCI_1A_2": {
								"JUNSCI_1A_2_2.5": {},
								"JUNSCI_1A_2_2.6": {},
								"JUNSCI_1A_2_2.3": {},
								"JUNSCI_1A_2_2.4": {},
								"JUNSCI_1A_2_2.1": {},
								"JUNSCI_1A_2_2.2": {}
							},
							"JUNSCI_1A_3": {
								"JUNSCI_1A_3_3.2": {},
								"JUNSCI_1A_3_3.3": {},
								"JUNSCI_1A_3_3.4": {}
							}
						}
					}
				},
				"metas": {
					"JUNSCI_1A_1_1.3": {
						"nameEn": "Section 1.3 Safety in the laboratory",
						"rnum": 7,
						"pMeta": "JUNSCI_1A_1",
						"nameCt": "ç« ç¯ 1.3 å¯¦é©å®¤å®å¨",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"JUNSCI_1A_1_1.4": {
						"nameEn": "Section 1.4 Laboratory apparatus and basic practical skills",
						"rnum": 8,
						"pMeta": "JUNSCI_1A_1",
						"nameCt": "ç« ç¯ 1.4 å¯¦é©å®¤åå¨ååºæ¬å¯¦é©æå·§",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"JUNSCI_1A_1": {
						"nameEn": "Unit 1 Introducing science",
						"rnum": 4,
						"pMeta": "JUNSCI_1A",
						"nameCt": "èª²æ¬¡ 1 ç§å­¸å¥é",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"JUNSCI_1A_2_2.1": {
						"nameEn": "Section 2.1 Change in states of water",
						"rnum": 10,
						"pMeta": "JUNSCI_1A_2",
						"nameCt": "ç« ç¯ 2.1 æ°´çç©æè®å",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 3
					},
					"JUNSCI_1A_2": {
						"metaSetId": "rs_junsci23",
						"nameEn": "Water",
						"rnum": 9,
						"LVNum": "2",
						"pMeta": "JUNSCI_1A",
						"nameCt": "æ°´",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"JUNSCI_1A_3": {
						"nameEn": "Unit 3 Looking at living things",
						"rnum": 16,
						"pMeta": "JUNSCI_1A",
						"nameCt": "èª²æ¬¡ 3 è§å¯çç©",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 2
					},
					"JUNSCI_1A": {
						"nameEn": "Book 1A",
						"rnum": 3,
						"pMeta": "JUNSCI",
						"nameCt": "èª²æ¬ 1A",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 1
					},
					"JUNSCI": {
						"nameEn": "Junior Secondary Science Mastering Concepts and Skills",
						"rnum": 2,
						"nameCt": "åä¸­ç§å­¸åºç¤æ¦å¿µèæè½",
						"metaSetId": "rs_junsci23",
						"pub": 1,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "ta",
				"MSReports": "subjects, SCII; subjects, SCIC; subjects, PHY",
				"MSNameEn": "MY Taxonomy 01",
				"MSLangCt": 1,
				"doReport": 1,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "my_taxon_01",
				"MSNameCt": "MY åé¡å­¸ 01",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Taxonomy L1",
						"nameCt": "åé¡å­¸ L1",
						"metaId": "ID - Level 1"
					},
					{
						"LVNum": "Index - Level 2",
						"nameEn": "Taxonomy L2",
						"nameCt": "åé¡å­¸ L2",
						"metaId": "ID - Level 2"
					},
					{
						"LVNum": "Index - Level 3",
						"nameEn": "Taxonomy L3",
						"nameCt": "åé¡å­¸ L3",
						"metaId": "ID - Level 3"
					}
				],
				"tree2": {
					"TAXON2": {
						"TAXON2_2": {
							"TAXON2_2_2": {},
							"TAXON2_2_1": {}
						},
						"TAXON2_1": {
							"TAXON2_1_2": {},
							"TAXON2_1_1": {}
						}
					},
					"TAXON1": {
						"TAXON1_2": {
							"TAXON1_2_2": {},
							"TAXON1_2_1": {}
						},
						"TAXON1_1": {
							"TAXON1_1_2": {},
							"TAXON1_1_1": {}
						}
					}
				},
				"metas": {
					"TAXON1_1_1": {
						"metaSetId": "my_taxon_01",
						"nameEn": "Taxonomy 1-1-1",
						"rnum": 4,
						"pMeta": "TAXON1_1",
						"nameCt": "åé¡å­¸ 1-1-1",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"TAXON1_1_2": {
						"metaSetId": "my_taxon_01",
						"nameEn": "Taxonomy 1-1-2",
						"rnum": 5,
						"pMeta": "TAXON1_1",
						"nameCt": "åé¡å­¸ 1-1-2",
						"pub": true,
						"display": 1,
						"lv": 2
					},
					"TAXON1_1": {
						"metaSetId": "my_taxon_01",
						"nameEn": "Taxonomy 1-1",
						"rnum": 3,
						"pMeta": "TAXON1",
						"nameCt": "åé¡å­¸ 1-1",
						"pub": true,
						"display": 1,
						"lv": 1
					},
					"TAXON1": {
						"metaSetId": "my_taxon_01",
						"nameEn": "Taxonomy 1",
						"rnum": 2,
						"nameCt": "åé¡å­¸ 1",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			},
			{
				"MSType": "di",
				"MSNameEn": "Question difficulty",
				"MSLangCt": 1,
				"doReport": 0,
				"MSLangEn": 1,
				"drpu": "P",
				"metaSetId": "rs_q_diff",
				"MSNameCt": "é¡ç®é£åº¦",
				"heads": [
					{
						"LVNum": "Index - Level 1",
						"nameEn": "Difficulty",
						"nameCt": "é£åº¦",
						"metaId": "ID - Level 1"
					}
				],
				"tree2": {
					"DI_EASY": {},
					"DI_HARD": {},
					"DI_MEDIUM": {}
				},
				"metas": {
					"DI_EASY": {
						"metaSetId": "rs_q_diff",
						"nameEn": "Easy",
						"rnum": 2,
						"nameCt": "å®¹æ",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"DI_HARD": {
						"metaSetId": "rs_q_diff",
						"nameEn": "Hard",
						"rnum": 4,
						"nameCt": "å°é£",
						"pub": true,
						"display": 1,
						"lv": 0
					},
					"DI_MEDIUM": {
						"metaSetId": "rs_q_diff",
						"nameEn": "Medium",
						"rnum": 3,
						"nameCt": "ä¸­ç­",
						"pub": true,
						"display": 1,
						"lv": 0
					}
				}
			}
		]
	},
	"datas": {
		"overData1a_": {
			"fb75e3ee-07e7-4657-ba80-1339a98c8f5f": {
				"sumAvgP": 338.034188034188,
				"count": 6
			}
		},
		"stuOverDataA_": {
			"ttl": 6,
			"pfa": 6,
			"p": 3,
			"f": 3,
			"a": 0,
			"n": 0,
			"avgP": 338.034188034188,
			"markP": 326.9230769230769
		},
		"overData2a_": {
			"cSums": {
				"fb75e3ee-07e7-4657-ba80-1339a98c8f5f": {
					"202307": {
						"count": 2,
						"sumAvgP": 111.11111111111111
					},
					"202308": {
						"count": 1,
						"sumAvgP": 76.92307692307692
					},
					"202311": {
						"count": 2,
						"sumAvgP": 100
					},
					"202403": {
						"count": 4,
						"sumAvgP": 50
					}
				}
			},
			"data": [
				{
					"referenceId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
					"month": "7",
					"progress": {
						"value": 2
					},
					"averageScore": {
						"percentage": 55.6
					}
				},
				{
					"referenceId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
					"month": "8",
					"progress": {
						"value": 1
					},
					"averageScore": {
						"percentage": 76.9
					}
				},
				{
					"referenceId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
					"month": "11",
					"progress": {
						"value": 2
					},
					"averageScore": {
						"percentage": 50
					}
				},
				{
					"referenceId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
					"month": "3",
					"progress": {
						"value": 4
					},
					"averageScore": {
						"percentage": 12.5
					}
				}
			]
		},
		"stuOverDataB": {
			"type": "s",
			"data": [
				{
					"month": "3",
					"averageScore": {
						"percentage": 25
					},
					"overview": [
						{
							"status": "f",
							"value": 2
						},
						{
							"status": "n",
							"value": 2
						}
					]
				},
				{
					"month": "7",
					"averageScore": {
						"percentage": 50
					},
					"overview": [
						{
							"status": "p",
							"value": 1
						},
						{
							"status": "f",
							"value": 1
						}
					]
				},
				{
					"month": "8",
					"averageScore": {
						"percentage": 76.9
					},
					"overview": [
						{
							"status": "p",
							"value": 1
						}
					]
				},
				{
					"month": "11",
					"averageScore": {
						"percentage": 100
					},
					"overview": [
						{
							"status": "p",
							"value": 1
						},
						{
							"status": "n",
							"value": 1
						}
					]
				}
			]
		},
		"asmData3_": [
			{
				"teacherId": "84a2ff1a-99af-4e3a-845d-ed0b276eafe6",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "111",
				"ALang": "en",
				"datePub": 1699266195509,
				"APass": 1,
				"submit": 1,
				"ttl": 1,
				"pass": 1,
				"fail": 0,
				"avgMp": 100,
				"passP": 60,
				"maxP": 100,
				"minP": 100,
				"id": "ArrRV88iwy3RX9PUQSgybm"
			},
			{
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "RS exercise test 001",
				"ALang": "en",
				"datePub": 1711438902512,
				"APass": 1,
				"submit": 0,
				"ttl": 1,
				"pass": 0,
				"fail": 0,
				"avgMp": 0,
				"passP": 60,
				"maxP": 0,
				"minP": 0,
				"id": "4yRgBWLDbKquLQPjWxoimf"
			},
			{
				"teacherId": "84a2ff1a-99af-4e3a-845d-ed0b276eafe6",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "EX-1354",
				"ALang": "ct",
				"datePub": 1690782902305,
				"APass": 1,
				"submit": 1,
				"ttl": 1,
				"pass": 1,
				"fail": 0,
				"avgMp": 100,
				"passP": 60,
				"maxP": 100,
				"minP": 100,
				"id": "DsyfTFbfnvbsNGeeqJeuHC"
			},
			{
				"teacherId": "2f9f7e16-924a-4183-957b-a398e24bcac7",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "RS exercise test 001",
				"ALang": "en",
				"datePub": 1699508961493,
				"APass": 1,
				"submit": 0,
				"ttl": 20,
				"pass": 0,
				"fail": 0,
				"avgMp": 0,
				"passP": 60,
				"maxP": 0,
				"minP": 0,
				"id": "PVha9YSQrTmNUjHH363H82"
			},
			{
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "private",
				"ALang": "en",
				"datePub": 1711419662672,
				"APass": 1,
				"submit": 1,
				"ttl": 1,
				"pass": 0,
				"fail": 1,
				"avgMp": 0,
				"passP": 60,
				"maxP": 0,
				"minP": 0,
				"id": "PPRLdp95chg2Y6qk5McKYJ"
			},
			{
				"teacherId": "c068cb10-7e3d-410d-ab16-c285357fc04a",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "RS exercise test 002 (Science)",
				"ALang": "en",
				"datePub": 1689231707343,
				"APass": 1,
				"submit": 2,
				"ttl": 2,
				"pass": 0,
				"fail": 2,
				"avgMp": 11.11111111111111,
				"passP": 60,
				"maxP": 22.22222222222222,
				"minP": 0,
				"id": "Bhrk5jwLWwaLVXowj5Qr2u"
			},
			{
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "private",
				"ALang": "en",
				"datePub": 1711419331748,
				"APass": 1,
				"submit": 1,
				"ttl": 3,
				"pass": 0,
				"fail": 1,
				"avgMp": 50,
				"passP": 60,
				"maxP": 50,
				"minP": 50,
				"id": "RGbCE2DjJn3grHCXbhdL9K"
			},
			{
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "Copy - kc math",
				"ALang": "en",
				"datePub": 1711438999674,
				"APass": 1,
				"submit": 0,
				"ttl": 1,
				"pass": 0,
				"fail": 0,
				"avgMp": 0,
				"passP": 60,
				"maxP": 0,
				"minP": 0,
				"id": "CgtSM2PMWQfmihe8NZrAkR"
			},
			{
				"teacherId": "c068cb10-7e3d-410d-ab16-c285357fc04a",
				"classId": "fb75e3ee-07e7-4657-ba80-1339a98c8f5f",
				"EName": "Copy - kc ex 001",
				"ALang": "ct",
				"datePub": 1691565180306,
				"APass": 1,
				"submit": 1,
				"ttl": 1,
				"pass": 1,
				"fail": 0,
				"avgMp": 76.92307692307692,
				"passP": 60,
				"maxP": 76.92307692307692,
				"minP": 76.92307692307692,
				"id": "T2iu4FZmoJ3jXELtQWueYG"
			}
		],
		"stuAsmData_": [
			{
				"id": "ArrRV88iwy3RX9PUQSgybm",
				"title": "111",
				"status": "p",
				"maxP": 100,
				"teacherId": "84a2ff1a-99af-4e3a-845d-ed0b276eafe6",
				"APass": 1,
				"passP": 60,
				"avgMp": 100,
				"ALang": "en",
				"datePub": 1699266195509,
				"eScore": 3
			},
			{
				"id": "4yRgBWLDbKquLQPjWxoimf",
				"title": "RS exercise test 001",
				"status": "n",
				"maxP": 0,
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"APass": 1,
				"passP": 60,
				"avgMp": 0,
				"ALang": "en",
				"datePub": 1711438902512,
				"eScore": 61
			},
			{
				"id": "DsyfTFbfnvbsNGeeqJeuHC",
				"title": "EX-1354",
				"status": "p",
				"maxP": 100,
				"teacherId": "84a2ff1a-99af-4e3a-845d-ed0b276eafe6",
				"APass": 1,
				"passP": 60,
				"avgMp": 100,
				"ALang": "ct",
				"datePub": 1690782902305,
				"eScore": 9
			},
			{
				"id": "PVha9YSQrTmNUjHH363H82",
				"title": "RS exercise test 001",
				"status": "n",
				"maxP": 0,
				"teacherId": "2f9f7e16-924a-4183-957b-a398e24bcac7",
				"APass": 1,
				"passP": 60,
				"avgMp": 0,
				"ALang": "en",
				"datePub": 1699508961493,
				"eScore": 58
			},
			{
				"id": "PPRLdp95chg2Y6qk5McKYJ",
				"title": "private",
				"status": "f",
				"maxP": 0,
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"APass": 1,
				"passP": 60,
				"avgMp": 0,
				"ALang": "en",
				"datePub": 1711419662672,
				"eScore": 2
			},
			{
				"id": "Bhrk5jwLWwaLVXowj5Qr2u",
				"title": "RS exercise test 002 (Science)",
				"status": "f",
				"maxP": 0,
				"teacherId": "c068cb10-7e3d-410d-ab16-c285357fc04a",
				"APass": 1,
				"passP": 60,
				"avgMp": 11.11111111111111,
				"ALang": "en",
				"datePub": 1689231707343,
				"eScore": 63
			},
			{
				"id": "RGbCE2DjJn3grHCXbhdL9K",
				"title": "private",
				"status": "f",
				"maxP": 50,
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"APass": 1,
				"passP": 60,
				"avgMp": 50,
				"ALang": "en",
				"datePub": 1711419331748,
				"eScore": 2
			},
			{
				"id": "CgtSM2PMWQfmihe8NZrAkR",
				"title": "Copy - kc math",
				"status": "n",
				"maxP": 0,
				"teacherId": "9a6fef37-f5c5-4d49-a0b3-b8f25c5a30de",
				"APass": 1,
				"passP": 60,
				"avgMp": 0,
				"ALang": "en",
				"datePub": 1711438999674,
				"eScore": 3
			},
			{
				"id": "T2iu4FZmoJ3jXELtQWueYG",
				"title": "Copy - kc ex 001",
				"status": "p",
				"maxP": 76.9,
				"teacherId": "c068cb10-7e3d-410d-ab16-c285357fc04a",
				"APass": 1,
				"passP": 60,
				"avgMp": 76.92307692307692,
				"ALang": "ct",
				"datePub": 1691565180306,
				"eScore": 13
			}
		],
		"clsDetailData_": {
			"sumStus": {
				"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
					"ttl": 6,
					"att": 0,
					"pass": 3,
					"fail": 3,
					"natt": 3,
					"minp": 0,
					"maxp": 100,
					"markp": 326.9230769230769
				},
				"66f28454-9e33-4538-af39-8dcac00b904a": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"9ec4db59-c009-4138-b326-33f43726f86a": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"20ec6c38-cc5c-412c-82bb-64450a983307": {
					"ttl": 1,
					"att": 0,
					"pass": 0,
					"fail": 1,
					"natt": 2,
					"minp": 22.22222222222222,
					"maxp": 22.22222222222222,
					"markp": 22.22222222222222
				},
				"a37da26c-f859-4f01-bd1b-116898edcf3c": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"cdc56bce-acb0-4140-a2ad-ab86b85e3e6d": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"9c928017-c4df-4da8-977b-ca6c7d1defd6": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"0aa7f811-528d-4257-b54f-e419d160688e": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"1f1c5879-e510-463c-8df6-8765df5bd2a1": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"520d94f1-6e20-4379-ad93-fa02486d11e5": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"14602804-7d3f-4dd1-9951-365b2a292742": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"063198e8-352f-48cd-b1e8-41d12d83790b": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"86068d0f-0704-4d21-8337-d801fa93a397": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"efb9e6bd-b89e-4bcc-acf8-cbf98299d306": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 2,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"825c21fe-7f9f-4955-8892-dddb91c10961": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"be1d97a4-45f0-4d8e-bbd7-ddea8bd3dd56": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"7dffb163-87fa-47d6-adf9-573bc0a188c0": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"383a395d-ecf7-4e62-be70-542bc354407b": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"62bf4a7f-85e1-4e45-aedc-5d69b66e6359": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"ee6eea10-1f96-41ee-8edd-d7f6f12ea9aa": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				}
			}
		},
		"clsDetailData_v2": {
			"sumStus": {
				"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
					"ttl": 6,
					"att": 0,
					"pass": 3,
					"fail": 3,
					"natt": 3,
					"minp": 0,
					"maxp": 100,
					"markp": 326.9230769230769
				},
				"66f28454-9e33-4538-af39-8dcac00b904a": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"9ec4db59-c009-4138-b326-33f43726f86a": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"20ec6c38-cc5c-412c-82bb-64450a983307": {
					"ttl": 1,
					"att": 0,
					"pass": 0,
					"fail": 1,
					"natt": 2,
					"minp": 22.22222222222222,
					"maxp": 22.22222222222222,
					"markp": 22.22222222222222
				},
				"a37da26c-f859-4f01-bd1b-116898edcf3c": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"cdc56bce-acb0-4140-a2ad-ab86b85e3e6d": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"9c928017-c4df-4da8-977b-ca6c7d1defd6": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"0aa7f811-528d-4257-b54f-e419d160688e": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"1f1c5879-e510-463c-8df6-8765df5bd2a1": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"520d94f1-6e20-4379-ad93-fa02486d11e5": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"14602804-7d3f-4dd1-9951-365b2a292742": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"063198e8-352f-48cd-b1e8-41d12d83790b": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"86068d0f-0704-4d21-8337-d801fa93a397": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"efb9e6bd-b89e-4bcc-acf8-cbf98299d306": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 2,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"825c21fe-7f9f-4955-8892-dddb91c10961": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"be1d97a4-45f0-4d8e-bbd7-ddea8bd3dd56": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"7dffb163-87fa-47d6-adf9-573bc0a188c0": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"383a395d-ecf7-4e62-be70-542bc354407b": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"62bf4a7f-85e1-4e45-aedc-5d69b66e6359": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				},
				"ee6eea10-1f96-41ee-8edd-d7f6f12ea9aa": {
					"ttl": 0,
					"att": 0,
					"pass": 0,
					"fail": 0,
					"natt": 1,
					"minp": 100,
					"maxp": 0,
					"markp": 0
				}
			},
			"sumStuM2d": {
				"3": {
					"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
						"ttl": 2,
						"att": 0,
						"pass": 0,
						"fail": 2,
						"natt": 2,
						"minp": 0,
						"maxp": 50,
						"markp": 50
					},
					"efb9e6bd-b89e-4bcc-acf8-cbf98299d306": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"20ec6c38-cc5c-412c-82bb-64450a983307": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					}
				},
				"7": {
					"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
						"ttl": 2,
						"att": 0,
						"pass": 1,
						"fail": 1,
						"natt": 0,
						"minp": 0,
						"maxp": 100,
						"markp": 100
					},
					"20ec6c38-cc5c-412c-82bb-64450a983307": {
						"ttl": 1,
						"att": 0,
						"pass": 0,
						"fail": 1,
						"natt": 0,
						"minp": 22.22222222222222,
						"maxp": 22.22222222222222,
						"markp": 22.22222222222222
					}
				},
				"8": {
					"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
						"ttl": 1,
						"att": 0,
						"pass": 1,
						"fail": 0,
						"natt": 0,
						"minp": 76.92307692307692,
						"maxp": 76.92307692307692,
						"markp": 76.92307692307692
					}
				},
				"11": {
					"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
						"ttl": 1,
						"att": 0,
						"pass": 1,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 100,
						"markp": 100
					},
					"66f28454-9e33-4538-af39-8dcac00b904a": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"9ec4db59-c009-4138-b326-33f43726f86a": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"20ec6c38-cc5c-412c-82bb-64450a983307": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"a37da26c-f859-4f01-bd1b-116898edcf3c": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"cdc56bce-acb0-4140-a2ad-ab86b85e3e6d": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"9c928017-c4df-4da8-977b-ca6c7d1defd6": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"0aa7f811-528d-4257-b54f-e419d160688e": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"1f1c5879-e510-463c-8df6-8765df5bd2a1": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"520d94f1-6e20-4379-ad93-fa02486d11e5": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"14602804-7d3f-4dd1-9951-365b2a292742": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"063198e8-352f-48cd-b1e8-41d12d83790b": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"86068d0f-0704-4d21-8337-d801fa93a397": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"efb9e6bd-b89e-4bcc-acf8-cbf98299d306": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"825c21fe-7f9f-4955-8892-dddb91c10961": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"be1d97a4-45f0-4d8e-bbd7-ddea8bd3dd56": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"7dffb163-87fa-47d6-adf9-573bc0a188c0": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"383a395d-ecf7-4e62-be70-542bc354407b": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"62bf4a7f-85e1-4e45-aedc-5d69b66e6359": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					},
					"ee6eea10-1f96-41ee-8edd-d7f6f12ea9aa": {
						"ttl": 0,
						"att": 0,
						"pass": 0,
						"fail": 0,
						"natt": 1,
						"minp": 100,
						"maxp": 0,
						"markp": 0
					}
				}
			}
		},
		"clsMetaData_": {
			"clsMsets": {
				"NCMATH2E_001": {
					"NCMATH2E_4A_5_5.1": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E_4A_1": {
						"cnt": 1,
						"markp": 66.7,
						"maxp": 66.7,
						"minp": 66.7
					},
					"NCMATH2E_4A_5_5.1_A": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E": {
						"cnt": 1,
						"markp": 38.1,
						"maxp": 38.1,
						"minp": 38.1
					},
					"NCMATH2E_4A": {
						"cnt": 1,
						"markp": 38.1,
						"maxp": 38.1,
						"minp": 38.1
					},
					"NCMATH2E_4A_5": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E_4A_2": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					}
				}
			},
			"stuMset2d": {
				"f6279ad2-7467-4f6f-bd85-5d4f5361bb55": {
					"NCMATH2E_001": {
						"NCMATH2E_4A_5_5.1": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E_4A_1": {
							"cnt": 4,
							"markp": 266.66666666666663,
							"maxp": 100,
							"minp": 0
						},
						"NCMATH2E_4A_5_5.1_A": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E": {
							"cnt": 7,
							"markp": 266.66666666666663,
							"maxp": 100,
							"minp": 0
						},
						"NCMATH2E_4A": {
							"cnt": 7,
							"markp": 266.66666666666663,
							"maxp": 100,
							"minp": 0
						},
						"NCMATH2E_4A_5": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E_4A_2": {
							"cnt": 1,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						}
					}
				},
				"20ec6c38-cc5c-412c-82bb-64450a983307": {
					"NCMATH2E_001": {}
				}
			}
		},
		"gradeMetaData_": {
			"clsMset2d": {
				"fb75e3ee-07e7-4657-ba80-1339a98c8f5f": {
					"NCMATH2E_001": {
						"NCMATH2E_4A_5_5.1": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E_4A_1": {
							"cnt": 3,
							"markp": 183.33333333333331,
							"maxp": 100,
							"minp": 0
						},
						"NCMATH2E_4A_5_5.1_A": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E": {
							"cnt": 3,
							"markp": 105.55555555555554,
							"maxp": 55.55555555555555,
							"minp": 0
						},
						"NCMATH2E_4A": {
							"cnt": 3,
							"markp": 105.55555555555554,
							"maxp": 55.55555555555555,
							"minp": 0
						},
						"NCMATH2E_4A_5": {
							"cnt": 2,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						},
						"NCMATH2E_4A_2": {
							"cnt": 1,
							"markp": 0,
							"maxp": 0,
							"minp": 0
						}
					}
				}
			},
			"gradeMsets": {
				"NCMATH2E_001": {
					"NCMATH2E_4A_5_5.1": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E_4A_1": {
						"cnt": 1,
						"markp": 61.11111111111111,
						"maxp": 61.11111111111111,
						"minp": 61.11111111111111
					},
					"NCMATH2E_4A_5_5.1_A": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E": {
						"cnt": 1,
						"markp": 35.18518518518518,
						"maxp": 35.18518518518518,
						"minp": 35.18518518518518
					},
					"NCMATH2E_4A": {
						"cnt": 1,
						"markp": 35.18518518518518,
						"maxp": 35.18518518518518,
						"minp": 35.18518518518518
					},
					"NCMATH2E_4A_5": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					},
					"NCMATH2E_4A_2": {
						"cnt": 1,
						"markp": 0,
						"maxp": 0,
						"minp": 0
					}
				}
			}
		},
		"stuMetaData_": {
			"rs_q_diff": {
				"DI_HARD": {
					"cnt": 2,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0
					]
				},
				"DI_EASY": {
					"cnt": 2,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0
					]
				},
				"DI_MEDIUM": {
					"cnt": 14,
					"markp": 166.66666666666666,
					"minp": 0,
					"maxp": 100,
					"list": [
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						100,
						66.66666666666666,
						0
					]
				}
			},
			"rs_junsci23": {
				"JUNSCI": {
					"cnt": 14,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"JUNSCI_1A": {
					"cnt": 14,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"JUNSCI_1A_1": {
					"cnt": 12,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"JUNSCI_1A_1_1.4": {
					"cnt": 11,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"JUNSCI_1A_1_1.1": {
					"cnt": 1,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0
					]
				},
				"JUNSCI_1A_1_1.3": {
					"cnt": 1,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0
					]
				},
				"JUNSCI_1A_2": {
					"cnt": 3,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0,
						0,
						0
					]
				},
				"JUNSCI_1A_2_2.1": {
					"cnt": 3,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0,
						0,
						0
					]
				}
			},
			"rs_grade": {
				"SEC_KS3_JunSec_S1": {
					"cnt": 14,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"SEC_KS3_JunSec": {
					"cnt": 14,
					"markp": 100,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				}
			},
			"subjects": {
				"SCII": {
					"cnt": 15,
					"markp": 200,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						100,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0,
						0
					]
				},
				"MATH_SS": {
					"cnt": 9,
					"markp": 366.66666666666663,
					"minp": 0,
					"maxp": 100,
					"list": [
						100,
						0,
						0,
						0,
						100,
						0,
						100,
						66.66666666666666,
						0
					]
				}
			},
			"my_values_01": {
				"V1": {
					"cnt": 1,
					"markp": 100,
					"minp": 100,
					"maxp": 100,
					"list": [
						100
					]
				},
				"V1_1": {
					"cnt": 1,
					"markp": 100,
					"minp": 100,
					"maxp": 100,
					"list": [
						100
					]
				},
				"V1_1_1": {
					"cnt": 1,
					"markp": 100,
					"minp": 100,
					"maxp": 100,
					"list": [
						100
					]
				}
			},
			"NCMATH2E_001": {
				"NCMATH2E": {
					"cnt": 7,
					"markp": 266.66666666666663,
					"minp": 0,
					"maxp": 100,
					"list": [
						0,
						0,
						100,
						0,
						100,
						66.66666666666666,
						0
					]
				},
				"NCMATH2E_4A": {
					"cnt": 7,
					"markp": 266.66666666666663,
					"minp": 0,
					"maxp": 100,
					"list": [
						0,
						0,
						100,
						0,
						100,
						66.66666666666666,
						0
					]
				},
				"NCMATH2E_4A_1": {
					"cnt": 4,
					"markp": 266.66666666666663,
					"minp": 0,
					"maxp": 100,
					"list": [
						0,
						100,
						100,
						66.66666666666666
					]
				},
				"NCMATH2E_4A_5": {
					"cnt": 2,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0,
						0
					]
				},
				"NCMATH2E_4A_5_5.1": {
					"cnt": 2,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0,
						0
					]
				},
				"NCMATH2E_4A_5_5.1_A": {
					"cnt": 2,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0,
						0
					]
				},
				"NCMATH2E_4A_2": {
					"cnt": 1,
					"markp": 0,
					"minp": 0,
					"maxp": 0,
					"list": [
						0
					]
				}
			}
		}
	}
});