import React, { useState } from 'react';

import { ReduxBind, debugMode } from '../saga/ReduxState';
import { lang_set, t2 } from '../saga/lang.saga';

import * as UI from '../libs/libUI';
import { redirectSignin } from '../libs/libMidWare';
import { apiCallLoad_ } from '../libs/awsFuncs';
import { _WebRoot } from '../consts/ATConstReact';
import { setAWSLogin } from './PageRedirect';
import { gst_set } from '../saga/globalState.saga';
import { useUILang } from '../AppExPf/utils/useUILang';

const stage = process.env.REACT_APP_CFG;

const PageLogin = ReduxBind(props => {
    const {dispatch} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [input, setInput] = useState(0);
    const [lid, setLid] = useState('tu123'); //u0321a
    const [pwd, setPwd] = useState('123');
    const [errLid, setErrLid] = useState('');
    const [errPwd, setErrPwd] = useState('');

    const path = window.location && window.location.pathname;
    const search = window.location && window.location.search;

    const onLogin = (res, err) => {
        if(err) console.error({PageLogin_:err});
        setAWSLogin(props.dispatch, res, err);
    }
    //lj({pageLogin:{errLid, errPwd}});

    const click = (e) => {
        setErrLid(lid?'':t2('err_plsInputID'));
        setErrPwd(pwd?'':t2('err_plsInputPass'));
        if(lid && pwd){
            setInput(2);
            apiCallLoad_(props.dispatch, '/dummyLogin', onLogin, {l:lid, p:pwd});
            //props.auth_login(state.login, state.pass);
        }
    };
    const loginClick = e => { UI.stopEvent(e);
        setInput(1);
        dispatch(gst_set('dummyLogin', 1));
    };
    const oupLoginClick = e => { UI.stopEvent(e);
        setInput(2);
        redirectSignin(uiEn, props.settings(), {state:{path, search}});
    };
    const setLang = (e) => { UI.stopEvent(e);
        const newLang = props.lang() === 'En'?'Ct':'En';
        props.dispatch(lang_set(newLang));
    };
    const showDummyLogin = debugMode() || (['local','dev', 'uat'].includes(stage) && path.startsWith(_WebRoot+'dummylogin'));
    return (<div className="tac m8 p8">
        {(input===2)?
            <div></div>
        :(input===1)?
            <div>
                <div className="m8">loginID 
                    {UI.Text0(lid, setLid, 'lid')}</div>
                {errLid && <div id='errLogin'>{(errLid)}</div>}
                <div className="m8">password
                    {UI.Pass0(pwd, setPwd, 'pwd')}</div>
                {errPwd && <div id='errPass'>{(errPwd)}</div>}
                {UI.Button0('login', click)}
            </div>
        :<div>
            <div className="m8">{UI.Button0('oup login', oupLoginClick)}</div>
            {showDummyLogin? <div className="m8">{UI.Button0('DUMMY LOGIN',  loginClick)}</div>: ''}
            <div className="m8">{UI.Button0('toggle lang', setLang)}</div>
        </div>
        }
    </div>
    );
});
export default PageLogin;

